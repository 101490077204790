import React from 'react';

import { REVIEW_QUESTION_TYPES } from '@learned/constants';
import _ from 'lodash';

import { FocusAreaAnswersPerJob } from '~/pages/ReviewGiveFeedback/components/Questions/Components/AllPreviousAnswers/AllSkillAnswers/FocusAreaAnswersPerJob';
import { IQuestionSkillCategorySubQuestion } from '~/pages/ReviewGiveFeedback/types';

import convertToTimeString from '~/utils/convertToTimeString';

import { FocusAreaComments } from '../../FocusAreaComments';
import { QuestionSeparator } from '../components/design';
import { ReviewInformationBar } from '../components/ReviewInformationBar';
import { IAllPreviousAnswersProps } from '../types';

const AllSkillAnswers: React.FC<
  IAllPreviousAnswersProps<
    REVIEW_QUESTION_TYPES.SKILL_CATEGORY | REVIEW_QUESTION_TYPES.CUSTOM_SKILL
  >
> = ({
  previousUserReviewWithQuestionAndAnswers,
  useMultiLangString,
  userReviewQuestionsIdsPerJob,
}) => {
  const getMultiLangString = useMultiLangString();

  return (
    <>
      {previousUserReviewWithQuestionAndAnswers.map((userReview) => {
        const { questionsWithAnswers, reviewName, reviewStartDate } = userReview;
        return (
          <>
            <ReviewInformationBar
              reviewName={getMultiLangString(reviewName)}
              reviewStartDate={convertToTimeString(reviewStartDate)}
            />
            {(userReviewQuestionsIdsPerJob || []).map(
              ({ jobProfileNameString, userReviewQuestionIds }, jobIndex) => {
                // define questions in job
                const questionsWithAnswersInJob = _.filter(
                  questionsWithAnswers || [],
                  (faQuestion) =>
                    userReviewQuestionIds.includes(
                      (faQuestion as unknown as IQuestionSkillCategorySubQuestion)?.question?.id,
                    ),
                ) as IQuestionSkillCategorySubQuestion[];
                return (
                  <FocusAreaAnswersPerJob
                    key={jobIndex || 'default'}
                    jobProfileNameString={jobProfileNameString}
                    useMultiLangString={useMultiLangString}
                    questionsWithAnswersInJob={questionsWithAnswersInJob}
                  />
                );
              },
            )}
            {questionsWithAnswers?.length && (
              <FocusAreaComments
                useMultiLangString={useMultiLangString}
                focusAreaQuestion={questionsWithAnswers.at(0)}
              />
            )}
            <QuestionSeparator />
          </>
        );
      })}
    </>
  );
};

export default AllSkillAnswers;
