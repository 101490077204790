import _ from 'lodash';

import {
  type IQuestionSkillCategoryData,
  IUserReviewQuestionSkillCategoryPopulated,
} from '../../../types';

import type { IJobProfile, IMultiLangString, IUserReviewPopulated, ISkill } from '@learned/types';

export const getSortedJobs = (
  focusAreaQuestions: IQuestionSkillCategoryData['subQuestions'],
  getMultiLangString: (multiLangString: IMultiLangString) => string,
  primaryJobProfileId?: string,
) => {
  const jobs: Record<
    IJobProfile['id'],
    { jobProfileId: IJobProfile['id']; jobProfileNameString: string }
  > = {};

  focusAreaQuestions.forEach((item) => {
    const questionData = item?.question as unknown as IUserReviewQuestionSkillCategoryPopulated;
    const jobProfileId = questionData?.settings?.jobProfile;
    const jobProfileName = questionData?.settings?.jobProfileName;

    if (jobProfileId && !jobs[jobProfileId]) {
      jobs[jobProfileId] = {
        jobProfileId,
        jobProfileNameString: getMultiLangString(jobProfileName) || '',
      };
    }
  });

  return _.sortBy(_.values(jobs), [
    (job) => job.jobProfileId !== primaryJobProfileId, // Primary job first
    'jobProfileNameString', // Then A → Z
  ]);
};

export const getSkillWithPopulatedSkillCategory = (
  skillId: ISkill['id'],
  userReview?: IUserReviewPopulated,
) => {
  const skill = userReview?.backup?.skills?.[skillId];
  if (!skill) {
    return null;
  }

  // populate skill-category
  const skillCategoryId = skill.skillCategory;
  const skillCategory = userReview?.backup?.skillCategories?.[skillCategoryId];

  if (!skillCategory) {
    return null;
  }

  return {
    ...skill,
    skillCategory,
  };
};
