import React, { useState } from 'react';

import { REVIEW_QUESTION_TYPES } from '@learned/constants';
import {
  IAllPreviousRatingResponse,
  ILatestPreviousRatingResponse,
  ISkill,
  type IUserReviewPopulated,
  IJobProfile,
} from '@learned/types';
import { map } from 'lodash';
import { Controller, useForm } from 'react-hook-form';

import { SkillModal, type ISkillModalProps } from '~/components/Modals/SkillModal';
import RickTextView from '~/components/RickTextView';
import ShowMore from '~/components/ShowMore';
import {
  getSkillWithPopulatedSkillCategory,
  getSortedJobs,
} from '~/pages/ReviewGiveFeedback/components/Questions/SkillQuestion/utils';
import { IAnswerType } from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/components/QuestionEditModal';

import { AllAnswersPerJob } from './AllAnswersPerJob';
import { FocusAreasList } from './FocusAreasList';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';

import { CommentSection, NAOption } from '../Components';
import AllAnswers, { ALL_ANSWERS_TAB } from '../Components/AllAnswers';
import AllPreviousAnswers from '../Components/AllPreviousAnswers';
import { FocusAreaComments } from '../Components/FocusAreaComments';
import {
  ContentWrapper,
  Description,
  QuestionHeader,
  QuestionInfoWrapper,
  QuestionWrapper,
  TextLabel,
} from '../design';

import type {
  IQuestionCustomSkillData,
  IQuestionSkillCategoryData,
  TPreviousUserReviewsWithAnswerPerReviewQuestion,
} from '../../../types';

export interface IQuestionViewSkillProps {
  focusAreaQuestions:
    | IQuestionCustomSkillData['subQuestions']
    | IQuestionSkillCategoryData['subQuestions'];
  languageState: ILanguageStateReturn;
  onChange?: (data: IAnswerType | IAnswerType[]) => void;
  defaultValues: ISkillQuestionForm;
  canAnswer: boolean;
  hasError?: boolean;
  hasCommentError?: boolean;
  showOtherRatings?: boolean;
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
  isDashboard?: boolean;
  previousRatings?: {
    latestPerUserReviewQuestion?: Record<string, ILatestPreviousRatingResponse>; // key: userReviewQuestionId
    allPerReviewQuestion?: Record<string, IAllPreviousRatingResponse[]>; // key: reviewQuestionId
  };
  isShowPreviousScore?: boolean;
  previousQuestionsAnswers?: TPreviousUserReviewsWithAnswerPerReviewQuestion;
  primaryJobProfileId?: IJobProfile['id'];
  userReview?: IUserReviewPopulated;
}

interface IJobFocusAreaList {
  jobProfileNameString?: string;
  focusAreaQuestions:
    | IQuestionSkillCategoryData['subQuestions']
    | IQuestionCustomSkillData['subQuestions'];
}

export type ISkillQuestionForm = {
  answers: { value: number | undefined }[];
  isNotApplicable?: boolean;
  comment?: string;
};

const DEFAULT_SELECTED_SKILL = null;
const DEFAULT_EXPAND_LEVELS: number[] = [];

const SkillQuestion = ({
  focusAreaQuestions,
  defaultValues,
  onChange,
  canAnswer,
  hasError,
  hasCommentError,
  showOtherRatings,
  useMultiLangString,
  isDashboard,
  previousRatings,
  isShowPreviousScore = false,
  primaryJobProfileId,
  userReview,
}: IQuestionViewSkillProps) => {
  const form = useForm<ISkillQuestionForm>({ defaultValues });
  const [selectedSkill, setSelectedSkill] = useState<ISkillModalProps['skill'] | null>(
    DEFAULT_SELECTED_SKILL,
  );
  const [defaultExpandLevels, setDefaultExpandLevels] = useState<number[]>(DEFAULT_EXPAND_LEVELS); // levels that will be expanded in SkillModal
  const { control, watch } = form;
  const notApplicableWatch = watch('isNotApplicable');
  const getMultiLangString = useMultiLangString();

  const relevantQuestion = focusAreaQuestions.at(0)?.question;
  const options = relevantQuestion?.settings.options;
  const isSkillCategoryQuestion = relevantQuestion?.type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY;

  const jobs = isSkillCategoryQuestion
    ? getSortedJobs(
        focusAreaQuestions as unknown as IQuestionSkillCategoryData['subQuestions'],
        getMultiLangString,
        primaryJobProfileId,
      )
    : [];

  const relevantQuestionPreviousRating = relevantQuestion?.id
    ? previousRatings?.latestPerUserReviewQuestion?.[relevantQuestion.id]
    : undefined;

  const isPreviouslySkipped =
    relevantQuestionPreviousRating?.rating?.answer === null && isShowPreviousScore;

  const isAnswerObligated = relevantQuestion?.settings?.isAnswerObligated || false;

  // for skill-category question -> we group questions per job
  // for custom-skill question -> we do not apply grouping
  const focusAreaLists: IJobFocusAreaList[] = isSkillCategoryQuestion
    ? map(jobs, ({ jobProfileId, jobProfileNameString }) => ({
        jobProfileNameString,
        focusAreaQuestions: (
          focusAreaQuestions as IQuestionSkillCategoryData['subQuestions']
        ).filter(
          (focusArea) => focusArea?.question?.settings?.jobProfile === jobProfileId,
        ) as IQuestionSkillCategoryData['subQuestions'],
      }))
    : [
        {
          jobProfileNameString: undefined,
          focusAreaQuestions: focusAreaQuestions as IQuestionCustomSkillData['subQuestions'],
        },
      ];

  // Helper function to calculate the starting index for focus area questions in each job profile
  // This ensures continuous numbering across multiple job profiles by adding up the number of
  // questions in all previous job profiles
  const defineFocusAreasIndexInJob = (focusAreaLists: IJobFocusAreaList[], jobIndex: number) => {
    return focusAreaLists
      .slice(0, jobIndex)
      .reduce(
        (acc: number, curr: { focusAreaQuestions: IJobFocusAreaList['focusAreaQuestions'] }) =>
          acc + curr.focusAreaQuestions.length,
        0,
      );
  };

  const openSkillModal = (skillId: ISkill['id'], expandLevels: number[]) => {
    const skill = getSkillWithPopulatedSkillCategory(skillId, userReview);

    if (skill) {
      setSelectedSkill(skill);
      setDefaultExpandLevels(expandLevels);
    }
  };
  const closeSkillModal = () => {
    setSelectedSkill(DEFAULT_SELECTED_SKILL);
    setDefaultExpandLevels(DEFAULT_EXPAND_LEVELS);
  };

  return (
    <QuestionWrapper $isDashboard={isDashboard} paddingTop="0">
      {canAnswer && (
        <>
          <ContentWrapper className={notApplicableWatch ? 'disabled' : ''}>
            <QuestionInfoWrapper>
              <QuestionHeader marginBottom="0">
                <TextLabel marginBottom="0" fontSize="18px">
                  {relevantQuestion && getMultiLangString(relevantQuestion.settings.skillName)}
                </TextLabel>

                {(!isAnswerObligated || isPreviouslySkipped) && (
                  <Controller
                    name="isNotApplicable"
                    control={control}
                    render={({ field }) => (
                      <NAOption
                        isChecked={field.value}
                        previousRatingTooltip={
                          isPreviouslySkipped
                            ? relevantQuestionPreviousRating?.userReview.name
                            : undefined
                        }
                        isAnswerObligated={relevantQuestion?.settings?.isAnswerObligated}
                        onChange={(value: boolean) => {
                          field.onChange(value);
                          focusAreaQuestions.forEach((fa) => {
                            onChange?.({
                              questionId: fa.question.id,
                              isNotApplicable: value,
                              answer: value ? null : -1, // when NA enabled -> we set value to null, when disabled -> we reset answer to -1
                              oldAnswer: value ? null : -1, // when NA enabled -> we set value to null, when disabled -> we reset answer to -1
                            });
                            if (fa.question.type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY) {
                              fa.question.settings.duplicateQuestions?.forEach((dupe) =>
                                onChange?.({
                                  questionId: dupe.question.id,
                                  isNotApplicable: value,
                                  answer: value ? null : -1, // when NA enabled -> we set value to null, when disabled -> we reset answer to -1
                                  oldAnswer: value ? null : -1, // when NA enabled -> we set value to null, when disabled -> we reset answer to -1
                                }),
                              );
                            }
                          });
                        }}
                      />
                    )}
                  />
                )}
              </QuestionHeader>
              {relevantQuestion && (
                <Description>
                  {/* @ts-ignore */}
                  <ShowMore showMoreClassName="showMore" maxHeight={50} key={relevantQuestion.id}>
                    <RickTextView
                      html={getMultiLangString(relevantQuestion.settings.skillDescription)}
                    />
                  </ShowMore>
                </Description>
              )}
            </QuestionInfoWrapper>

            {focusAreaLists.map(({ jobProfileNameString, focusAreaQuestions }, jobIndex) => (
              <FocusAreasList
                key={jobIndex || 'default'}
                jobProfileName={jobProfileNameString}
                previousRatings={previousRatings}
                options={options}
                focusAreaQuestions={focusAreaQuestions}
                hasError={hasError}
                form={form}
                onChange={onChange}
                notApplicableWatch={notApplicableWatch}
                focusAreaQuestionFirstIndex={defineFocusAreasIndexInJob(focusAreaLists, jobIndex)}
                useMultiLangString={useMultiLangString}
                openSkillModal={openSkillModal}
                userReview={userReview}
              />
            ))}
          </ContentWrapper>
          {relevantQuestion?.settings?.isCommentsAllowed && (
            <CommentSection
              hasError={hasCommentError}
              formMethods={form}
              isRequired={!notApplicableWatch && relevantQuestion?.settings?.isCommentsObligated}
              onChange={(comment) => {
                const questions: {
                  questionId: string;
                  answer?: string | number | null;
                  comment?: string;
                  isNotApplicable?: boolean;
                }[] = [];
                focusAreaQuestions.forEach((fa) => {
                  questions.push({ questionId: fa.question.id, comment });
                  if (fa.question.type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY) {
                    fa.question.settings.duplicateQuestions?.forEach((dupe) =>
                      onChange?.({ questionId: dupe.question.id, comment }),
                    );
                  }
                });
                onChange?.(questions);
              }}
            />
          )}
        </>
      )}

      <AllAnswers
        isAllAnswersEnabled={Boolean(showOtherRatings)}
        isPreviousAnswersEnabled={isShowPreviousScore}
      >
        {(activeTab: ALL_ANSWERS_TAB) => (
          <>
            {activeTab === ALL_ANSWERS_TAB.ALL_ANSWERS && (
              <>
                {focusAreaLists.map(({ jobProfileNameString, focusAreaQuestions }, jobIndex) => (
                  <AllAnswersPerJob
                    key={jobIndex || 'default'} // for custom-skill we do not display job
                    jobProfileName={jobProfileNameString}
                    focusAreaQuestions={focusAreaQuestions}
                    useMultiLangString={useMultiLangString}
                  />
                ))}
                <FocusAreaComments
                  useMultiLangString={useMultiLangString}
                  focusAreaQuestion={focusAreaQuestions.at(0)}
                />
              </>
            )}
            {activeTab === ALL_ANSWERS_TAB.PREVIOUS_ANSWERS && (
              <AllPreviousAnswers
                useMultiLangString={useMultiLangString}
                reviewQuestionId={relevantQuestion?.reviewQuestion || ''}
                userReviewQuestions={focusAreaQuestions.map((fa) => fa.question)}
                // group userReviewQuestions per job
                userReviewQuestionsIdsPerJob={focusAreaLists.map(
                  ({ jobProfileNameString, focusAreaQuestions }) => ({
                    jobProfileNameString,
                    userReviewQuestionIds: focusAreaQuestions.map((fa) => fa.question.id),
                  }),
                )}
              />
            )}
          </>
        )}
      </AllAnswers>
      {selectedSkill && (
        <SkillModal
          onClose={closeSkillModal}
          skill={selectedSkill}
          defaultExpandLevels={defaultExpandLevels}
          useMultiLangString={useMultiLangString}
        />
      )}
    </QuestionWrapper>
  );
};

export { SkillQuestion };
