import { useEffect, useState } from 'react';

import {
  REVIEW_QUESTION_EVALUATORS,
  REVIEW_QUESTION_TYPES,
  REVIEW_QUESTION_TYPES_V1,
  REVIEW_RATING_TYPE,
  TASK_STATUS,
  TASK_TYPE,
} from '@learned/constants';

import {
  groupQuestionsBasedOnType,
  IUserReviewQuestionCustomSkillGrouped,
  IUserReviewQuestionCustomSkillV1Grouped,
  IUserReviewQuestionSkillCategoryGrouped,
  IUserReviewQuestionSkillCategoryV1Grouped,
} from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/utils';

import { RATING_USER_TYPE } from '../types';

import type {
  IReviewRating,
  IReviewTheme,
  ITask,
  IUserReview,
  IUserReviewQuestion,
  IUserReviewQuestionGoalPlan,
  IUserReviewQuestionRating,
  IUserReviewQuestionText,
  WithExtends,
} from '@learned/types';

export type UserReview = Omit<IUserReview, 'userReviewQuestions'> & {
  tasks: ITask[];
  userReviewQuestions: IUserReviewQuestion[];
};

export type IUserReviewQuestionGrouped =
  | WithExtends<
      IUserReviewQuestionText & { reviewRatings?: IReviewRating[] },
      { theme?: IReviewTheme }
    >
  | WithExtends<
      IUserReviewQuestionRating & { reviewRatings?: IReviewRating[] },
      { theme?: IReviewTheme }
    >
  | IUserReviewQuestionGoalPlan
  | WithExtends<IUserReviewQuestionCustomSkillGrouped, { theme?: IReviewTheme }>
  | WithExtends<IUserReviewQuestionSkillCategoryGrouped, { theme?: IReviewTheme }>
  | WithExtends<IUserReviewQuestionCustomSkillV1Grouped, { theme?: IReviewTheme }>
  | WithExtends<IUserReviewQuestionSkillCategoryV1Grouped, { theme?: IReviewTheme }>;

export const useReviewRatings = ({ userReview }: { userReview?: UserReview }) => {
  const [reviewRatings, setReviewRatings] = useState<IUserReviewQuestionGrouped[]>([]);

  const getReviewRatingType = (reviewTask: ITask) => {
    switch (reviewTask.type) {
      case TASK_TYPE.REVIEW_SELF_EVALUATE:
        return {
          reviewType: RATING_USER_TYPE.SELF,
          type: REVIEW_RATING_TYPE.SELF,
          task: reviewTask.id,
        };
      case TASK_TYPE.REVIEW_COACH_EVALUATE:
        return {
          reviewType: RATING_USER_TYPE.COACH,
          type: REVIEW_RATING_TYPE.COACH,
          task: reviewTask.id,
        };
      case TASK_TYPE.REVIEW_PEER_EVALUATE:
        return reviewTask?.userTo?.email
          ? {
              reviewType: RATING_USER_TYPE.EXTERNAL_PEER,
              type: REVIEW_RATING_TYPE.PEER,
              task: reviewTask.id,
            }
          : {
              reviewType: RATING_USER_TYPE.PEER,
              type: REVIEW_RATING_TYPE.PEER,
              task: reviewTask.id,
            };
    }
  };

  const getEmptyTextRatingQuestion = (
    question: IUserReviewQuestion & { reviewRatings?: IReviewRating[] },
    reviewTask: ITask,
  ) => {
    return {
      // we have to use -1 and '' to start with because null means user refused to answer
      answer: question.type === REVIEW_QUESTION_TYPES.RATING ? -1 : '',
      comment: null,
      createdBy: reviewTask.userTo,
      company: question.company,
      createdFor: reviewTask.userFrom,
      userReview: reviewTask.target,
      userReviewQuestion: question.id,
    };
  };

  const mapReviewRating = (
    question: IUserReviewQuestion & { reviewRatings?: IReviewRating[] },
    task: ITask,
  ) => {
    const rating = question.reviewRatings?.find((rating) => rating.task === task.id);
    const reviewRating = rating || getEmptyTextRatingQuestion(question, task);
    return {
      ...reviewRating,
      ...getReviewRatingType(task),
    };
  };

  const getTextReviewRatings = (
    question: IUserReviewQuestion & { reviewRatings?: IReviewRating[] },
    userReview: UserReview,
  ) => {
    const evaluators =
      (question?.settings as IUserReviewQuestionText['settings'])?.evaluators || [];
    const isEmployeeEvaluator = evaluators.includes(REVIEW_QUESTION_EVALUATORS.EMPLOYEE);
    const isPeerEvaluator = evaluators.includes(REVIEW_QUESTION_EVALUATORS.PEER);
    const isCoachEvaluator = evaluators.includes(REVIEW_QUESTION_EVALUATORS.COACH);
    const selfReviewTask = userReview.tasks.find(
      (task) => task.type === TASK_TYPE.REVIEW_SELF_EVALUATE,
    );
    let employeeReviews;
    if (selfReviewTask) {
      const reviewRating =
        question.reviewRatings?.find((rating) => rating.task === selfReviewTask?.id) ||
        getEmptyTextRatingQuestion(question, selfReviewTask);
      employeeReviews = { ...reviewRating, ...getReviewRatingType(selfReviewTask) };
    }

    const coachReviewTasks = userReview.tasks.filter(
      (task) =>
        task.type === TASK_TYPE.REVIEW_COACH_EVALUATE && task.status !== TASK_STATUS.DECLINED,
    );
    const coachReviews = coachReviewTasks
      .map((task) => mapReviewRating(question, task))
      .filter(Boolean);

    const internalPeerReviewTasks = userReview.tasks.filter(
      (task) => task.type === TASK_TYPE.REVIEW_PEER_EVALUATE && task.userTo?.id,
    );
    const externalPeerReviewTasks = userReview.tasks.filter(
      (task) => task.type === TASK_TYPE.REVIEW_PEER_EVALUATE && task.userTo?.email,
    );
    const internalPeerReviews = internalPeerReviewTasks
      .map((task) => mapReviewRating(question, task))
      .filter(Boolean);
    const externalPeerReviews = externalPeerReviewTasks
      .map((task) => mapReviewRating(question, task))
      .filter(Boolean);
    return [
      isEmployeeEvaluator && employeeReviews,
      ...(isCoachEvaluator ? coachReviews : []),
      ...(isPeerEvaluator ? internalPeerReviews : []),
      ...(isPeerEvaluator ? externalPeerReviews : []),
    ].filter(Boolean);
  };

  const getAllReviewRatings = (userReview: UserReview) => {
    const questions = groupQuestionsBasedOnType(userReview);

    const ratings = userReview.userReviewQuestions.map((userReviewQuestion) => {
      switch (userReviewQuestion.type) {
        case REVIEW_QUESTION_TYPES.TEXT:
        case REVIEW_QUESTION_TYPES.RATING:
          return {
            ...userReviewQuestion,
            reviewRatings: getTextReviewRatings(userReviewQuestion, userReview),
          };
        case REVIEW_QUESTION_TYPES.GOAL_PLAN:
          return userReviewQuestion;
        case REVIEW_QUESTION_TYPES.SKILL_CATEGORY: {
          const skillCategory = userReviewQuestion.settings.skillCategory;
          const skillCategoryQuestionIndex = questions.findIndex(
            (question) =>
              question.type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY &&
              question.skillCategory === skillCategory,
          );
          if (skillCategoryQuestionIndex === -1) {
            return;
          }

          const [skillCategoryQuestion] = questions.splice(skillCategoryQuestionIndex, 1);
          return skillCategoryQuestion;
        }
        case REVIEW_QUESTION_TYPES.CUSTOM_SKILL: {
          // @ts-ignore
          const key = userReviewQuestion.settings.skill.id;
          const skillCategoryQuestionIndex = questions.findIndex((question) => {
            return question.type === REVIEW_QUESTION_TYPES.CUSTOM_SKILL && question.skillId === key;
          });
          if (skillCategoryQuestionIndex === -1) {
            return;
          }

          const [skillCategoryQuestion] = questions.splice(skillCategoryQuestionIndex, 1);
          return skillCategoryQuestion;
        }
        case REVIEW_QUESTION_TYPES_V1.CUSTOM_SKILL_V1: {
          // @ts-ignore
          const key = userReviewQuestion.settings.skill.id;
          const skillCategoryQuestionIndex = questions.findIndex(
            (question) =>
              question.type === REVIEW_QUESTION_TYPES_V1.CUSTOM_SKILL_V1 &&
              question.skillId === key,
          );
          if (skillCategoryQuestionIndex === -1) {
            return;
          }

          const [skillCategoryQuestion] = questions.splice(skillCategoryQuestionIndex, 1);
          return skillCategoryQuestion;
        }
        case REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1: {
          // @ts-ignore
          const key = userReviewQuestion.settings.jobProfile.id;
          const skillCategoryQuestionIndex = questions.findIndex(
            (question) =>
              question.type === REVIEW_QUESTION_TYPES_V1.JOB_PROFILE_V1 &&
              question.jobProfileId === key,
          );
          if (skillCategoryQuestionIndex === -1) {
            return;
          }

          const [skillCategoryQuestion] = questions.splice(skillCategoryQuestionIndex, 1);
          return skillCategoryQuestion;
        }
      }
    });

    return ratings.flat();
  };

  useEffect(() => {
    if (userReview) {
      const reviewRatings = getAllReviewRatings(userReview);
      setReviewRatings(reviewRatings.filter(Boolean) as IUserReviewQuestionGrouped[]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReview]);

  return { reviewRatings };
};
