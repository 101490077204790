import { SKILL_CATEGORIES_ID } from '@learned/constants';
import {
  IMultiLangString,
  IReviewQuestionSkillCategory,
  ISkillCategory,
  ISkill,
  IUserReviewPopulated,
  IReviewQuestion,
} from '@learned/types';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';

export const mockSkill = (question: IReviewQuestion) =>
  ({
    id: `${question.id}-skill`, // important - this is skillID from generateSkillCategoryQuestion
  } as unknown as ISkill);

export const mockUserReview = (skillCategory: ISkillCategory, skill: ISkill, i18n: I18n) =>
  ({
    backup: {
      skillCategories: {
        [skillCategory.id]: skillCategory,
      },
      skills: {
        [skill.id]: {
          ...skill,
          name: 'Skill of KPI',
          description: 'Skill description',
          skillCategory: skillCategory.id,
          focusAreas: [
            {
              level: 0,
              values: [
                { id: 1, name: i18n._(t`Level general: focus area 1`) },
                { id: 2, name: i18n._(t`Level general: focus area 2`) },
                { id: 3, name: i18n._(t`Level general: focus area 3`) },
              ],
            },
            {
              level: 1,
              values: [
                { id: 4, name: i18n._(t`Level operational: focus area 1`) },
                { id: 5, name: i18n._(t`Level operational: focus area 2`) },
                { id: 6, name: i18n._(t`Level operational: focus area 3`) },
              ],
            },
            {
              level: 2,
              values: [
                { id: 7, name: i18n._(t`Level tactical: focus area 1`) },
                { id: 8, name: i18n._(t`Level tactical: focus area 2`) },
                { id: 9, name: i18n._(t`Level tactical: focus area 3`) },
              ],
            },
            {
              level: 3,
              values: [
                { id: 10, name: i18n._(t`Level strategic: focus area 1`) },
                { id: 11, name: i18n._(t`Level strategic: focus area 1`) },
                { id: 12, name: i18n._(t`Level strategic: focus area 1`) },
              ],
            },
          ],
        },
      },
    },
  } as unknown as IUserReviewPopulated);

export const generateSkillCategoryQuestion = (
  i: IReviewQuestionSkillCategory & { skillCategory: ISkillCategory },
  skillOptions: { label: IMultiLangString }[],
  i18n: I18n,
  index: number,
) => ({
  ...i,
  reviewQuestion: i.id,
  id: `${i.id}-${index}`,
  settings: {
    ...i.settings,
    options: skillOptions,
    careerPlan: `${i.id}-careerPlan`,
    jobProfile: `${i.id}-jobProfile`,
    skill: `${i.id}-skill`,
    focusArea: {
      id: `${i.id}-${index}`,
      name: i18n._(t`Focus area ${index + 1}`),
    },
    skillCategory: i.skillCategory.id,
    skillName: i18n._(t`Skill or KPI`),
    skillDescription: {
      en: 'Skill description',
      fi: 'Skill description',
    },
    skillFocusAreas: [0, 1, 2].map((faLevel) => {
      return {
        level: i.skillCategory.type === SKILL_CATEGORIES_ID.KPIS ? 0 : faLevel, // kpi has only 1 level by default
        values: [{ id: `${i.id}-${faLevel}` }],
      };
    }),
    themeName: i.themeName,
    // @ts-ignore
    jobProfileName: i18n._(t`Job name`),
  },
});
