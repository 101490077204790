import React from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { union } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import qs from 'qs';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import Button from '~/components/Button';
import { confirm } from '~/components/ConfirmDialog';
import { Icon, ICONS, ICON_SIZES } from '~/components/Icon';
import IconButton from '~/components/IconButton';
import Switch from '~/components/Switch';
import { FieldGroup, FieldTitle } from '~/components/Text';
import AvatarCard from '~/components/UI/AvatarCard';
import { AddParticipants } from '~/pages/ConversationSetup/components/AddParticipants';

import { CONVERSATION_STATUSES } from '~/constants';
import useBoolState from '~/hooks/useBoolState';
import { getUser } from '~/selectors/baseGetters';
import {
  getCreator,
  getId,
  getParticipants,
  getStatus,
  getSubject,
} from '~/selectors/currentConversation';
import { setParticipants, setSubject } from '~/store/currentConversation/actions';
import { COLOR_PALETTE, COLORS } from '~/styles';

const StyledFieldGroup = styled(FieldGroup)`
  margin-bottom: 40px;
`;

const UserSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ParticipantsWrapper = styled.div`
  overflow: auto;
  max-height: 300px;
  background: inherit;
`;

const AvatarRow = styled.div`
  padding: 10px 12px 10px 9px;
  border-radius: 6px;
  border: solid 1px #dbe1ef;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const LeftSection = styled.div`
  width: 166px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const StyledFieldTitle = styled(FieldTitle)``;

const InfoSection = styled.div`
  color: ${COLORS.STATUS_ARCHIVED};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const RowExplanation = styled.div`
  color: ${COLORS.STATUS_ARCHIVED};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  flex: 1;
  display: flex;
  margin-right: 12px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
`;

const WideButton = styled(Button)`
  border: 1px solid #dbe1ef;
  border-radius: 6px;
  font-size: 14px;
  font-weight: bold;
`;

const ParticipantsSection = ({ isPreselectedSubject }) => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const participants = useSelector(getParticipants);
  const conversationId = useSelector(getId);
  const status = useSelector(getStatus);
  const creator = useSelector(getCreator);
  const user = useSelector(getUser);
  const $isAddParticipantsModal = useBoolState(false);
  const subject = useSelector(getSubject);

  const history = useHistory();
  const query = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const memberId = query?.users?.[0];
  const isAdmin = user?.isAdmin;
  const isCreator = !creator || user?.id === creator; // on creation - creator is undefined

  const isAllowedToDelete = (participantId) => {
    // No possible delete: if 1-1 created from PublicProfile - we add team member automatically
    const isParticipantAddedAutomatically = participantId === memberId;

    return !isParticipantAddedAutomatically;
  };

  const addCoaches = (newCoaches) => {
    dispatch(setParticipants(union(participants, newCoaches)));
    $isAddParticipantsModal.off();
  };

  const changeSubject = (subjectId) => {
    dispatch(setSubject(subjectId));
  };

  const removeCoach = (coachId) => {
    const newParticipants = participants.filter((c) => c !== coachId);
    dispatch(setParticipants(newParticipants));
  };

  const askToRemoveCoach = async (coachId) => {
    if (conversationId && status !== CONVERSATION_STATUSES.DRAFT.key) {
      const confirmOptions = {
        title: i18n._(t`Are you sure?`),
        confirmText: i18n._(t`Delete`),
        cancelText: i18n._(t`Cancel`),
      };
      if (
        await confirm(
          i18n,
          i18n._(
            t`You are about to delete a participant from the conversation. All talking points and or comments made by this participant will be deleted. This cannot be undone.`,
            i18n,
          ),
          confirmOptions,
        )
      ) {
        removeCoach(coachId);
      }
    } else {
      removeCoach(coachId);
    }
  };

  return (
    <StyledFieldGroup>
      <StyledFieldTitle>
        <Trans>Guests</Trans>
      </StyledFieldTitle>
      <InfoSection>
        <Trans>
          Switch on the employee this meeting is about setting for a 1:1 meeting to see employee
          information. Switch off for a team meeting.
        </Trans>
      </InfoSection>
      <RowExplanation>
        <Trans>Guests</Trans>
        <LeftSection>
          <Trans>Employee this meeting is about</Trans>
        </LeftSection>
      </RowExplanation>
      <UserSection>
        <ParticipantsWrapper>
          {!isEmpty(participants) &&
            participants.map((pId) => (
              <AvatarRow key={pId}>
                <AvatarCard type={AvatarCard.TYPES.SMALL} userId={pId} size={28} />
                <LeftSection>
                  <Switch
                    checked={subject === pId}
                    disabled={isPreselectedSubject || (subject?.length && subject !== pId)}
                    onChange={() => changeSubject(subject === pId ? '' : pId)}
                  />
                  {(isAdmin || isCreator) && isAllowedToDelete(pId) && isAllowedToDelete() && (
                    <IconButton
                      size={24}
                      color="none"
                      noBorder
                      onClick={() => askToRemoveCoach(pId)}
                    >
                      <Icon
                        icon={ICONS.DELETE_BIN}
                        size={ICON_SIZES.LARGE}
                        color={COLOR_PALETTE.GRAY_MIDDLE}
                      />
                    </IconButton>
                  )}
                </LeftSection>
              </AvatarRow>
            ))}
        </ParticipantsWrapper>
        <WideButton
          label={`+ ${i18n._(t`Guests`)}`}
          type="primary-border-wide"
          onClick={$isAddParticipantsModal.on}
        />
      </UserSection>
      {$isAddParticipantsModal.value && (
        <AddParticipants
          onSave={addCoaches}
          title={i18n._(t`Add guest(s)`)}
          onClose={$isAddParticipantsModal.off}
          isHideSkillsFilter
          isSingleSelect={false}
          usersToHide={participants}
          isOnlyCoachTeamMembers={false}
        />
      )}
    </StyledFieldGroup>
  );
};

export default React.memo(ParticipantsSection);
