import { createAction } from 'redux-act';

export const setConversation = createAction('set conversation');
export const setName = createAction('set name');
export const setDescription = createAction('set description');
export const setStartDate = createAction('set start time');
export const setEndDate = createAction('set end time');
export const setIncludeLinkMeeting = createAction('set include link meeting');
export const addTalkingPoint = createAction('add talking point');
export const addTalkingPoints = createAction('add talking points');
export const removeTalkingPoint = createAction('remove talking point');
export const removeTalkingPoints = createAction('remove talking points');
export const setTalkingPoints = createAction('clean up talking points');
export const updateTalkingPoint = createAction('update talking point');
export const updateDeadline = createAction('update deadline');
export const updateIsApproval = createAction('update is approval');
export const addSingleConversationUser = createAction('add single conversation user');
export const removeSingleConversationUser = createAction('remove single conversation user');
export const addUsers = createAction('add users');
export const removeUser = createAction('remove user');
export const setCoach = createAction('set user coach');
export const setParticipants = createAction('set participants');
export const setSubject = createAction('set subject');
export const setCreatedBy = createAction('set createdBy');
export const incrementTalkingPointOrder = createAction('increment talking point order');
export const decrementTalkingPointOrder = createAction('decrement talking point order');
export const setRepeat = createAction('set repeat rule');
export const setRepeatType = createAction('set repeat.type');
export const setRepeatUntil = createAction('set repeat.until');
export const setRepeatEvery = createAction('set repeat.every');
export const setRepeatDeadlines = createAction('set repeat.deadlines');
