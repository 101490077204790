import React, { ReactNode, useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { get } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { AutocompleteFilterMembers } from '~/components/AutoCompleteDropdownFilters';
import { TableList } from '~/components/TableList';
import { CalendarDropdown } from '~/pages/Conversations/components/CalendarDropdown';
import type { IFilterType } from '~/pages/OnboardAndLearn/tabs/AllLearningsTab/types';

import { COLUMNS, SORT_OPTIONS } from './columns';

import { CONVERSATION_COLLECTION_TYPES, ROLES } from '~/constants';
import routes from '~/constants/routes';
import useDebounce from '~/hooks/useDebounce';
import { LS_KEYS, useLocalStorage } from '~/hooks/useLocalStorage';
import { getSelectedRole, getUser } from '~/selectors/baseGetters';
import { getConversationsAndReviews } from '~/services/userConversations';
import { getConversationPath } from '~/utils/userConversationsUtils';

import type { IReviewOld, IUser } from '@learned/types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 8px 8px 0 rgba(145, 157, 165, 0.12);
`;

const StyledAutocompleteMembers = styled(AutocompleteFilterMembers)`
  width: 140px;
  max-width: 140px;
`;

export interface IType {
  id: string;
  key: string;
  name: string;
  translated: (i18n: any) => ReactNode;
}

const PAGE_SIZE = 10;
const DEFAULT_PAGINATION = { skip: 0, limit: PAGE_SIZE, index: 1 };
const LS_KEY = LS_KEYS.LS_DEVELOPMENT_MEETINGS;

type filterType = {
  isShowFilters: boolean;
  search: string;
  statuses: string[];
  types: string[];
  sortBy: SORT_OPTIONS | undefined;
  selectedDateOption: IType | undefined;
  selectedCoaches: IUser[];
  pagination: { skip: number; limit: number; index: number };
  selectedSubjects: IUser[];
};

const initialFilters: filterType = {
  isShowFilters: false,
  search: '',
  statuses: [],
  types: [],
  sortBy: SORT_OPTIONS.DATE_SOON_LATER,
  selectedDateOption: undefined,
  selectedCoaches: [],
  pagination: DEFAULT_PAGINATION,
  selectedSubjects: [],
};

const MeetingsTab = () => {
  const { i18n } = useLingui();
  const params = useParams();
  const user = useSelector(getUser);
  const history = useHistory();
  const selectedRole = useSelector(getSelectedRole);
  const [items, setItems] = useState<IReviewOld[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentFilters, setCurrentFilters] = useLocalStorage(LS_KEY, initialFilters);
  const [isLoading, setIsLoading] = useState(false);
  const { isShowFilters: _isShowFilters, ...debCurrentFilters } = useDebounce(currentFilters, 300); // isShowFilters does not affect on reFetch

  const { originalConversation: originalConversationFromQuery }: { originalConversation?: string } =
    qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

  // we use this only for redirect from calendar event
  // to define meeting name and put it in the search
  useEffect(() => {
    try {
      const fetch = async () => {
        const userId = get(params, 'userId');
        const { conversations: itemsFromRequest } = await getConversationsAndReviews({
          search: null,

          // filter by originalConversation from query
          // we use query for url from calendar event
          originalConversation: originalConversationFromQuery,

          // requirements
          tabsType: 'all',
          collectionType: CONVERSATION_COLLECTION_TYPES.CONVERSATION.key,
          userId: userId || user.id,
        });

        // set search with meeting name
        if (!isEmpty(itemsFromRequest)) {
          const meetingName = (Object.values(itemsFromRequest)[0] as unknown as { name: string })
            ?.name;
          if (meetingName) {
            setCurrentFilters((prevState: IFilterType) => ({
              ...prevState,
              search: meetingName,
              pagination: DEFAULT_PAGINATION, // reset pagination
            }));
          }
        }

        // delete originalConversation from query after usage
        const queryParams = new URLSearchParams(location.search);

        const key = 'originalConversation';
        if (queryParams.has(key)) {
          queryParams.delete(key);
          history.replace({
            search: queryParams.toString() + '#meetings',
          });
        }
      };

      if (originalConversationFromQuery) {
        fetch();
      }
    } catch (_e) {
      // do nothing
    }

    // eslint-disable-next-line
  }, [originalConversationFromQuery]);

  const fetchData = async () => {
    setIsLoading(true);

    const userId = get(params, 'userId');
    const { conversations: items, total } = await getConversationsAndReviews({
      search: debCurrentFilters.search || null,

      // filters
      coaches: debCurrentFilters.selectedCoaches.length
        ? debCurrentFilters.selectedCoaches.map((i: { id: string }) => i.id)
        : null,
      ...(debCurrentFilters.selectedDateOption && {
        startDate: debCurrentFilters.selectedDateOption.fromDate,
      }),
      ...(debCurrentFilters.selectedDateOption && {
        endDate: debCurrentFilters.selectedDateOption.toDate,
      }),
      ...(debCurrentFilters.selectedSubjects.length
        ? {
            subject: debCurrentFilters.selectedSubjects.map((i: { id: string }) => i.id),
          }
        : null),

      // requirements
      tabsType: currentFilters.selectedDateOption ? 'range' : 'all',
      collectionType: CONVERSATION_COLLECTION_TYPES.CONVERSATION.key,
      userId: userId || user.id,

      // options
      skip: debCurrentFilters.pagination.skip,
      limit: debCurrentFilters.pagination.limit,
      order: debCurrentFilters.sortBy,
    });

    setItems(Object.values(items));
    // when there is a date selected we should base on the total as it could have past events also
    setTotalCount(total);
    setIsLoading(false);
  };

  // change filters fetch
  useEffect(() => {
    fetchData();

    // eslint-disable-next-line
  }, [JSON.stringify(debCurrentFilters)]);

  const actionButton = {
    label: t`Create meeting`,
    onClick: () => {
      history.push(
        routes.CONVERSATION_CREATE.build(
          undefined,
          // @ts-ignore
          {
            isBackPath: true,
          },
        ),
      );
    },
  };

  const filters = {
    isShowFilters: currentFilters.isShowFilters,
    search: currentFilters.search,
    setSearch: (value: string) =>
      setCurrentFilters((prevState: IFilterType) => ({
        ...prevState,
        search: value,
        pagination: DEFAULT_PAGINATION, // reset pagination
      })),

    // @ts-ignore
    onChangeFilter: (key, value) => {
      setCurrentFilters({ ...debCurrentFilters, pagination: DEFAULT_PAGINATION, [key]: value });
    },
    resetFilters: () => setCurrentFilters(initialFilters),
    selectedDateOption: currentFilters.selectedDateOption,
    selectedCoaches: currentFilters.selectedCoaches,
  };

  return (
    <Wrapper>
      <TableList
        data={items}
        columns={COLUMNS}
        onRowClick={(item) => {
          const path = getConversationPath({
            conversation: item,
            selectedRole,
            userId: user.id,
          });
          history.push(path as string);
        }}
        sortProps={{
          sortBy: currentFilters.sortBy,
          setSortBy: (sortBy: SORT_OPTIONS) => setCurrentFilters({ ...currentFilters, sortBy }),
        }}
        paginationProps={{
          pagination: currentFilters.pagination,
          changePagination: ({ skip, limit, index }) =>
            setCurrentFilters({
              ...currentFilters,
              pagination: { ...currentFilters.pagination, skip, limit, index },
            }),
          totalCount,
        }}
        isLoading={isLoading}
        placeholderProps={{
          noResultText: i18n._(t`No 1:1s found`),
          emptyStateText: i18n._(t`No 1:1s yet…`),
        }}
        actionButton={actionButton}
        filtersProps={{
          filters,
          isFiltered: !!currentFilters.search.length,
          isToggleHideFilterVisible: true,
          resetFilters: filters.resetFilters,
          filterComponents: (
            <>
              {selectedRole === ROLES.USER && (
                <StyledAutocompleteMembers
                  placeholder={i18n._(t`Employee`)}
                  selectedItems={currentFilters.selectedSubjects}
                  onChange={(value: IUser[]) => filters.onChangeFilter('selectedSubjects', value)}
                />
              )}
              {selectedRole === ROLES.USER && (
                <StyledAutocompleteMembers
                  placeholder={i18n._(t`Guests`)}
                  selectedItems={currentFilters.selectedCoaches}
                  onChange={(value: IUser[]) => filters.onChangeFilter('selectedCoaches', value)}
                />
              )}
              <CalendarDropdown
                disabled={false}
                selectedItem={filters.selectedDateOption}
                // @ts-ignore
                setSelectedItem={(value: IType[]) =>
                  filters.onChangeFilter('selectedDateOption', value)
                }
                filterOptions={[2, 1, 5, 6, 9]}
              />
            </>
          ),
        }}
      />
    </Wrapper>
  );
};

export { MeetingsTab };
