import React, { useEffect, useState } from 'react';

import { ROLES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { isEmpty } from 'lodash';
import qs from 'qs';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { PageTitle, PageDescription } from '~/components/Page';
import Shadow from '~/components/UI/Shadow';
import SetupLayout from '~/layouts/SetupLayout';
import CreatorSection from '~/pages/ConversationSetup/sections/Creator';
import DatesSection from '~/pages/ConversationSetup/sections/Dates';
import DescriptionSection from '~/pages/ConversationSetup/sections/Description';
import NameSection from '~/pages/ConversationSetup/sections/Name';
import ParticipantsSection from '~/pages/ConversationSetup/sections/Participants';
import TalkingPointsSection from '~/pages/ConversationSetup/sections/TalkingPoints';
import TemplateSection from '~/pages/ConversationSetup/sections/Template';

import HeaderActions from './components/HeaderActions';
import defaultConversation from './defaultConversation';

import useBoolState from '~/hooks/useBoolState';
import { getSelectedRole, getUser } from '~/selectors/baseGetters';
import { getConversation } from '~/services/conversations';
import { setConversation, addTalkingPoints } from '~/store/currentConversation/actions';
import { COLOR_PALETTE } from '~/styles';

const Content = styled(Shadow)`
  background: ${COLOR_PALETTE.WHITE};
  padding: 20px;
  margin-bottom: 24px;
`;

export const ConversationSetupContext = React.createContext();

const ConversationSetup = ({
  history,
  location,
  match: {
    params: { conversationId },
  },
}) => {
  const dispatch = useDispatch();
  const $loading = useBoolState(false);
  const $error = useBoolState(false);
  const isUpdate = !isEmpty(conversationId);
  const [isPreselectedSubject, setIsPreselectedSubject] = useState(false);
  const role = useSelector(getSelectedRole);
  const currentUser = useSelector(getUser);
  const [initConversation, setInitConversation] = useState(null);
  const {
    target: preSelectedTarget,
    targetName: preSelectedTargetName,
    targetType: preSelectedTargetType,
    users: preSelectParticipants,
  } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  useEffect(() => {
    const mount = async () => {
      $loading.on();
      let conversation;
      if (conversationId) {
        // conversation from DB
        conversation = await getConversation(conversationId, { join: ['talkingPoints'] });
        setIsPreselectedSubject(!!conversation.subject);
      } else {
        // new conversation
        conversation = defaultConversation({
          currentUser,
          role,
          preSelectParticipants,
          onlyUser: !(currentUser.isAdmin || currentUser.isCoach),
        });
      }

      setInitConversation(conversation);
      dispatch(setConversation(conversation));

      // pre-select target
      if (preSelectedTarget && preSelectedTargetName && preSelectedTargetType) {
        dispatch(
          addTalkingPoints([
            {
              target: preSelectedTarget,
              name: preSelectedTargetName,
              type: preSelectedTargetType,
            },
          ]),
        );
      }

      $loading.off();
    };

    mount();

    return () => {
      // clear store
      dispatch(setConversation());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    const url = new URL(window.location.href);
    const from = location.hash.slice(1)
      ? url.searchParams.get('from') + '#' + location.hash.slice(1)
      : url.searchParams.get('from');

    if (from) {
      return history.push(from);
    }
    history.goBack();
  };

  return (
    <ConversationSetupContext.Provider
      value={{ error: $error, loading: $loading, initConversation, isAdmin: role === ROLES.ADMIN }}
    >
      <SetupLayout
        title={<Trans>Conversations</Trans>}
        onClose={handleClose}
        width={'750px'}
        actions={<HeaderActions loading={$loading} />}
      >
        <PageTitle>
          <Trans id={isUpdate ? t`Edit 1-1 conversation` : t`Create 1-1 conversation`} />
        </PageTitle>
        <PageDescription>
          <Trans>Set an agenda to discuss with others</Trans>
        </PageDescription>
        <Content>
          <TemplateSection />
          <NameSection />
          <DescriptionSection />
          <ParticipantsSection
            isPreselectedSubject={preSelectParticipants?.length || isPreselectedSubject}
          />
          {role === ROLES.ADMIN && <CreatorSection />}
          <DatesSection />
          <TalkingPointsSection />
        </Content>
      </SetupLayout>
    </ConversationSetupContext.Provider>
  );
};

export default ConversationSetup;
