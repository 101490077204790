import {
  FocusAreaType,
  REVIEW_QUESTION_TYPES,
  REVIEW_TYPES,
  SKILL_CATEGORIES_ID,
  TASK_STATUS,
} from '@learned/constants';
import {
  ICompany,
  IMultiLangString,
  IReviewQuestionSkillCategory,
  ISkill,
  ISkillCategory,
  IUser,
} from '@learned/types';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';

import { PopulatedReviewTemplate } from '~/pages/ReviewTemplateView/types';
import { ISelectedFocusArea } from '~/pages/ReviewThemeSetup/types';

const date = new Date();
const startDate = new Date(date.setDate(date.getDate() - 1));
const endDate = new Date(date.setDate(date.getDate() + 8));

const generateSkillCategoryQuestion = (
  i: IReviewQuestionSkillCategory & { skillCategory: ISkillCategory },
  skillOptions: { label: IMultiLangString }[],
  i18n: I18n,
  index: number,
) => ({
  ...i,
  reviewQuestion: i.id,
  id: `${i.id}-${index}`,
  settings: {
    ...i.settings,
    options: skillOptions,
    careerPlan: `${i.id}-careerPlan`,
    jobProfile: `${i.id}-jobProfile`,
    skill: `${i.id}-skill`,
    focusArea: {
      id: `${i.id}-${index}`,
      name: i18n._(t`Focus area ${index + 1}`),
    },
    skillCategory: i.skillCategory.id,
    skillName: i18n._(t`Skill or KPI`),
    skillDescription: {
      en: 'Skill description',
      fi: 'Skill description',
    },
    skillFocusAreas: [0, 1, 2].map((faLevel) => {
      return {
        level: i.skillCategory.type === SKILL_CATEGORIES_ID.KPIS ? 0 : faLevel, // kpi has only 1 level by default
        values: [{ id: `${i.id}-${faLevel}` }],
      };
    }),
    themeName: i.themeName,
    // @ts-ignore
    jobProfileName: i18n._(t`Job name`),
  },
});

const generateQuestions = (
  template: PopulatedReviewTemplate,
  currentCompany: ICompany,
  skills: ISkill[],
  i18n: I18n,
  getMultiLangString: (multiLangString: string | Record<string, string>) => string,
) => {
  const {
    products: {
      performance: {
        settings: {
          labels: { ratingLabels, skillLabels },
        },
      },
    },
  } = currentCompany;
  const options = ratingLabels.map((i: { name: IMultiLangString }) => ({ label: i.name }));
  const skillOptions = skillLabels.map((i: { name: IMultiLangString }) => ({ label: i.name }));

  // @ts-ignore
  const questions = [];
  template.questions.map((question) => {
    if (question.type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY) {
      const skillCategoryQuestion = question as unknown as IReviewQuestionSkillCategory & {
        skillCategory: ISkillCategory;
      };

      questions.push(
        ...[0, 1, 2].map((questionIndex) =>
          generateSkillCategoryQuestion(
            skillCategoryQuestion,
            skillCategoryQuestion.skillCategory.type === 'kpis' ? options : skillOptions,
            i18n,
            questionIndex,
          ),
        ),
      );

      return;
    }
    if (question.type === REVIEW_QUESTION_TYPES.CUSTOM_SKILL) {
      const options =
        question.settings.options ||
        skillLabels.map((i: { name: IMultiLangString }) => ({ label: i.name }));
      const questionName = getMultiLangString(
        // @ts-ignore
        question.name.length ? turnArrayIntoMultiLang(question.name) : question.name,
      );
      question.settings.skills.map((questionSkill, index) => {
        const focusAreas: any[] = [];
        const relevantSkill = skills.find((skill: ISkill) => questionSkill.skill === skill.id);
        if (!relevantSkill) {
          return;
        }
        questionSkill.selectedFocusAreas.forEach(
          ({ type, level, focusArea }: ISelectedFocusArea) => {
            const FAs: { id: string; name: IMultiLangString }[] = [];
            switch (type) {
              case FocusAreaType.SELECT_LEVEL: {
                relevantSkill.focusAreas
                  .filter((populatedFA) => populatedFA.level === level)
                  .map((populatedFA) => {
                    populatedFA.values.map((FA) =>
                      FAs.push({
                        id: FA.id,
                        name: FA.name,
                      }),
                    );
                  });
                break;
              }
              case FocusAreaType.SELECT_FOCUS_AREA: {
                relevantSkill.focusAreas
                  .find((populatedFA) => populatedFA.level === level)
                  ?.values.map((fa) => {
                    if (fa.id === focusArea) {
                      // @ts-ignore
                      FAs.push(fa);
                    }
                  });
                break;
              }
            }
            focusAreas.push({ level, values: FAs });
          },
        );

        // @ts-ignore
        focusAreas.map((FA, iIndex) => {
          // @ts-ignore
          FA.values.map((value, jIndex) => {
            questions.push({
              ...question,
              id: `${question.id}-${index}${iIndex}${jIndex}`,
              name: questionName,
              settings: {
                ...question.settings,
                focusArea: value,
                options,
                // @ts-ignore
                skillFocusAreas: focusAreas,
                // @ts-ignore
                skill: relevantSkill.id,
                // @ts-ignore
                skillName: relevantSkill.name,
                // @ts-ignore
                skillDescription: relevantSkill.description,
                themeName: question.themeName,
              },
            });
          });
        });
      });
      return;
    }
    questions.push({
      ...question,
      settings: {
        ...question.settings,
        themeName: question.themeName,
        options:
          question.type === REVIEW_QUESTION_TYPES.RATING
            ? question.settings.options || options
            : null,
      },
    });
    return {
      ...question,
      settings: {
        ...question.settings,
        themeName: question.themeName,
        options:
          question.type === REVIEW_QUESTION_TYPES.RATING
            ? question.settings.options || options
            : null,
      },
    };
  });
  // @ts-ignore
  return questions;
};

export const createFakeReview = (
  template: PopulatedReviewTemplate,
  currentCompany: ICompany,
  skills: ISkill[],
  skillCategories: Record<ISkillCategory['id'], ISkillCategory>,
  i18n: I18n,
  getMultiLangString: (multiLangString: string | Record<string, string>) => string,
) => {
  const questions = generateQuestions(template, currentCompany, skills, i18n, getMultiLangString);

  return {
    ...template,
    peers: [],
    settings: {
      startDate,
      endDate,
      isCoachesAskedToScheduleReview: true,
      isAutoArchive: true,
      isAutoTimeline: true,
      isCalibrate: true,
      isDigitalSign: true,
    },
    coaches: [],
    // @ts-ignore
    questions,
    userReviewQuestions: questions.map(({ id }) => id),
    backup: {
      skillCategories,
      skills: questions.reduce((obj, item) => {
        if (item.settings.skill) {
          obj[item.settings.skill] = {
            id: item.settings.skill,
            name: item.settings.skillName,
            description: item.settings.skillDescription,
            skillCategory: item.settings.skillCategory,
            focusAreas: [
              {
                level: 0,
                values: [
                  { id: 1, name: i18n._(t`Level general: focus area 1`) },
                  { id: 2, name: i18n._(t`Level general: focus area 2`) },
                  { id: 3, name: i18n._(t`Level general: focus area 3`) },
                ],
              },
              {
                level: 1,
                values: [
                  { id: 4, name: i18n._(t`Level operational: focus area 1`) },
                  { id: 5, name: i18n._(t`Level operational: focus area 2`) },
                  { id: 6, name: i18n._(t`Level operational: focus area 3`) },
                ],
              },
              {
                level: 2,
                values: [
                  { id: 7, name: i18n._(t`Level tactical: focus area 1`) },
                  { id: 8, name: i18n._(t`Level tactical: focus area 2`) },
                  { id: 9, name: i18n._(t`Level tactical: focus area 3`) },
                ],
              },
              {
                level: 3,
                values: [
                  { id: 10, name: i18n._(t`Level strategic: focus area 1`) },
                  { id: 11, name: i18n._(t`Level strategic: focus area 1`) },
                  { id: 12, name: i18n._(t`Level strategic: focus area 1`) },
                ],
              },
            ],
          };
        }

        return obj;
      }, {}),
    },
  };
};

export const createFakeReviewTask = (template: PopulatedReviewTemplate, currentUser: IUser) => {
  return {
    company: template.company,
    data: {
      reviewId: 'fakeReviewId',
    },
    deadLine: endDate,
    description: template.description,
    id: 'fakeId',
    isDeleted: false,
    name: template.name,
    startDate,
    status: TASK_STATUS.TODO,
    target: 'fakeTargetId',
    targetCollection: 'userReviews',
    type: REVIEW_TYPES.SELF,
    userFrom: currentUser.id,
    userTo: {
      id: currentUser.id,
    },
  };
};
