import React from 'react';

import { DashboardSidebar } from '~/components/DashboardSidebar';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { UserPreviewDrawer } from '~/components/UserPreviewDrawer';
import { InputNoLongerPossible } from '~/pages/ReviewGiveFeedback/components/InputNoLongerPossible';

import { MainWrapper, Wrapper, PlaceholderWrapper } from './design';
import { DashboardLayoutProps } from './types';

import useBoolState from '~/hooks/useBoolState';

const DashboardLayout = ({
  placeholderStatus,
  userId,
  userReviewId,
  sidebar,
  main,
  isLoading,
  showUserPreviewDrawer,
}: DashboardLayoutProps) => {
  const isMenuOpened = useBoolState(true);

  return (
    <>
      <ShowSpinnerIfLoading loading={!!isLoading && !userId}>
        {placeholderStatus ? (
          <PlaceholderWrapper>
            <InputNoLongerPossible status={placeholderStatus} />
          </PlaceholderWrapper>
        ) : (
          <Wrapper isMenuOpened={isMenuOpened.value}>
            <DashboardSidebar
              isMenuOpened={isMenuOpened.value}
              setIsMenuOpened={isMenuOpened.set}
              items={sidebar.items}
              buttons={sidebar.buttons}
            />
            <MainWrapper>
              <div className="content">{main}</div>
            </MainWrapper>
            {userId && userReviewId && showUserPreviewDrawer && (
              <UserPreviewDrawer
                userReviewId={userReviewId}
                userId={userId}
                position={{
                  top: 64,
                  right: 13,
                }}
              />
            )}
          </Wrapper>
        )}
      </ShowSpinnerIfLoading>
    </>
  );
};

export { DashboardLayout };
