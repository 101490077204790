import React from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import Tippy from '@tippyjs/react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { Avatar } from '~/components/UserAvatar/components/Avatar';
import { UserAvatarGroup } from '~/components/UserAvatar/UserAvatarGroup';

import type { IColumnTable } from '~/@types/table';
import { getActiveAndInactiveUsers } from '~/selectors/baseGetters';
import { COLORS } from '~/styles';
import convertToTimeString, { TIME_FORMATS } from '~/utils/convertToTimeString';

import { FlexRow, NameRow } from '../../../../design';

import type { IReviewOld } from '@learned/types';
import type { I18n } from '@lingui/core';

const Name = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  cursor: pointer;

  &::after {
    content: '';
    display: block;
  }
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: -0.18px;
`;

const Text = styled.div`
  word-break: break-word;
  white-space: pre-wrap;
  max-width: 250px;
`;

const RepeatRow = styled.div<{ unplanned?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  width: 140px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  ${({ unplanned }) =>
    unplanned &&
    css`
      color: ${COLORS.CONFIRMATION_MODAL_DELETE};
    `}
`;

const DateRow = styled.div<{ unplanned?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  width: 170px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  ${({ unplanned }) =>
    unplanned &&
    css`
      color: ${COLORS.CONFIRMATION_MODAL_DELETE};
    `}
`;

const tippyOpts = {
  modifiers: [
    {
      name: 'computeStyles',
      options: {
        gpuAcceleration: false,
      },
    },
  ],
};

export enum SORT_OPTIONS {
  NAME_A_Z = 'name_asc',
  NAME_Z_A = 'name_desc',
  SUBJECT_A_Z = 'subject_asc',
  SUBJECT_Z_A = 'subject_desc',
  DATE_SOON_LATER = 'date_asc',
  DATE_LATER_SOON = 'date_desc',
}

const WithColumn = ({ item }: { item: any }) => {
  return (
    <UserAvatarGroup
      users={item.participants
        .filter((i: string) => i !== item.subject)
        .map((item: string) => ({ userId: item }))}
      options={{ isCounterClickable: true }}
    />
  );
};

const EmployeeColumn = ({ item }: { item: IReviewOld }) => {
  const users = useSelector(getActiveAndInactiveUsers);
  if (!item.subject) {
    return null;
  }
  const user = users[item.subject];
  return <Avatar imgUrl={user?.avatarUrl} />;
};

const RepeatColumn = ({ item }: { item: any }) => {
  const { i18n } = useLingui();
  const getRepeatLabel = () => {
    if (!item.isRepeated) {
      return null;
    }
    if (item.repeat.every !== 1) {
      return i18n._(t`Custom`);
    }
    if (item.repeat.type === 'week') {
      return i18n._(t`Weekly`);
    }
    if (item.repeat.type === 'year') {
      return i18n._(t`Yearly`);
    }
  };

  return <RepeatRow>{getRepeatLabel()}</RepeatRow>;
};

const COLUMNS: IColumnTable<IReviewOld>[] = [
  {
    name: (i18n: I18n) => i18n._(t`Name`),
    accessor: 'name',
    maxWidth: '350px',
    renderCell: (item) => {
      const conversationName = item.name;
      return (
        <NameRow>
          {/* @ts-ignore */}
          <FlexRow>
            <Tippy
              trigger="mouseenter"
              theme="light"
              popperOptions={tippyOpts}
              content={<Text>{conversationName}</Text>}
            >
              <Name>{conversationName}</Name>
            </Tippy>
          </FlexRow>
        </NameRow>
      );
    },
    sortBy: {
      asc: {
        key: SORT_OPTIONS.NAME_A_Z,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: SORT_OPTIONS.NAME_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Employee`),
    accessor: 'employee',
    minWidth: '82px',
    renderCell: (item: IReviewOld) => <EmployeeColumn item={item} />,
    sortBy: {
      asc: {
        key: SORT_OPTIONS.SUBJECT_A_Z,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: SORT_OPTIONS.SUBJECT_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
  },
  {
    name: (i18n: I18n) => i18n._(t`Guests`),
    accessor: 'with',
    minWidth: '162px',
    renderCell: (item: IReviewOld) => <WithColumn item={item} />,
  },
  {
    name: (i18n: I18n) => i18n._(t`Repeat`),
    accessor: 'repeat',
    minWidth: '100px',
    renderCell: (item: IReviewOld) => <RepeatColumn item={item} />,
  },
  {
    name: (i18n: I18n) => i18n._(t`Date`),
    accessor: 'date',
    minWidth: '100px',
    sortBy: {
      asc: {
        key: SORT_OPTIONS.DATE_SOON_LATER,
        label: (i18n: I18n) => i18n._(t`Soon - later`),
      },
      desc: {
        key: SORT_OPTIONS.DATE_LATER_SOON,
        label: (i18n: I18n) => i18n._(t`Later - soon`),
      },
    },
    renderCell: (item: any) => {
      return (
        <DateRow>
          {item.dateOfConversation || item.startDate ? (
            <DateRow>{`${convertToTimeString(
              item.dateOfConversation || item.startDate,
            )} | ${convertToTimeString(
              item.dateOfConversation || item.startDate,
              TIME_FORMATS.CLASSIC_NO_DATE,
            )}`}</DateRow>
          ) : (
            <DateRow>
              <Trans>Not planned</Trans>
            </DateRow>
          )}
        </DateRow>
      );
    },
  },
];

export { COLUMNS };
