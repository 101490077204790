import React, { memo } from 'react';

import { IReviewRating, IUser, IUserReview } from '@learned/types';

import RickTextView from '~/components/RickTextView';
import { UserAvatar } from '~/components/UserAvatar';
import type {
  IUserReviewQuestionCustomSkillGrouped,
  IUserReviewQuestionSkillCategoryGrouped,
} from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/utils';

import { Arrow, Comment, EditButtonWrapper, Name } from './design';
import { RoleName } from './RoleName';
import { Comments, Row, RowChildHeader } from './SkillAnswersModal.design';
import { SkillRowModal } from './SkillRowModal';

import { useMultiLangString } from '~/hooks/useMultiLangString';

const LefColumnRows = memo(
  ({
    setRowHeaders,
    setOpenRows,
    openRows,
    ratingFilter,
    skillsFromJobProfile,
  }: {
    userReview: IUserReview;
    setRowHeaders: (
      func: (
        rowHeaders: Record<string, HTMLDivElement | null>,
      ) => Record<string, HTMLDivElement | null>,
    ) => void;
    setOpenRows: (func: (rows: number[]) => number[]) => void;
    skillsFromJobProfile: (
      | IUserReviewQuestionSkillCategoryGrouped['skills'][0]
      | IUserReviewQuestionCustomSkillGrouped['skills'][0]
    )[];
    openRows: number[];
    ratingFilter: (rating?: IReviewRating) => boolean;
  }) => {
    const getMultiLangString = useMultiLangString();

    return (
      <>
        {skillsFromJobProfile.map((skill, i) => {
          const isOpen = openRows.includes(i);
          return (
            <>
              <SkillRowModal
                ref={(el: HTMLDivElement) =>
                  setRowHeaders((prev) => {
                    return { ...prev, [`skill-${skill.skillId}`]: el };
                  })
                }
                onClick={() =>
                  setOpenRows((prev: number[]) =>
                    prev.includes(i) ? [...prev.filter((value) => value !== i)] : [...prev, i],
                  )
                }
                key={skill.skillId}
                isOpen={isOpen}
                skill={skill}
                ratingFilter={ratingFilter}
              />
              {isOpen && (
                <>
                  {skill.questions.map((question) => {
                    return (
                      <RowChildHeader
                        ref={(el) =>
                          setRowHeaders((prev) => {
                            return {
                              ...prev,
                              [`${skill.skillId}-${question.id}-${question.settings.focusArea.id}`]:
                                el,
                            };
                          })
                        }
                        key={question.id}
                      >
                        {getMultiLangString(question.settings.focusArea.name)}
                      </RowChildHeader>
                    );
                  })}

                  <Row
                    ref={(el) =>
                      setRowHeaders((prev) => {
                        return {
                          ...prev,
                          [`${skill.skillId}-comments`]: el,
                        };
                      })
                    }
                  >
                    <Comments>
                      {(skill.questions.at(0)?.reviewRatings ?? []).filter(ratingFilter).map(
                        (rating) =>
                          rating.comment && (
                            <Comment key={rating.id}>
                              <Name>
                                {rating.createdBy.id ? (
                                  <UserAvatar userId={rating.createdBy.id as string} />
                                ) : (
                                  <UserAvatar
                                    user={
                                      {
                                        firstName: '',
                                        lastName: '',
                                        email: rating.createdBy.email as string,
                                      } as IUser
                                    }
                                  />
                                )}{' '}
                                <span className="role">
                                  (<RoleName ratingType={rating.type} />)
                                </span>
                              </Name>
                              <EditButtonWrapper>
                                <Arrow />
                                <RickTextView
                                  className="comment"
                                  html={rating.comment ?? undefined}
                                />
                              </EditButtonWrapper>
                            </Comment>
                          ),
                      )}
                    </Comments>
                  </Row>
                </>
              )}
            </>
          );
        })}
      </>
    );
  },
);

export { LefColumnRows };
