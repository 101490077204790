import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

export const Wrapper = styled.div<{
  isMenuCollapsed?: boolean;
  position?: {
    top?: number;
    right?: number;
  };
}>`
  display: flex;
  width: ${({ isMenuCollapsed }) => (isMenuCollapsed ? '52px' : '380px')};
  border-right: 1px solid ${COLORS.BORDERS};
  box-sizing: border-box;
  position: fixed;
  height: ${({ position }) => (position?.top ? `calc(100vh - ${position.top}px)` : '100vh')};
  top: ${({ position }) => (position?.top ? `${position.top}px` : '0px')};
  right: ${({ position }) => (position?.right ? `${position.right}px` : '0px')};
  bottom: 0;
  background-color: ${COLORS.WHITE};
  z-index: 1000;
  transition: all ease 0.3s;
  overflow: hidden;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border-top: solid 1px ${COLORS.BORDERS};
`;

export const Tab = styled.div<{ color?: string; active?: boolean }>`
  max-width: 38px;
  margin-bottom: 16px;
  padding-right: 6px;
  border-right-width: 2px;
  border-right-style: solid;
  border-right-color: ${({ color, active }) => (active && color ? color : 'transparent')};
  color: ${({ color, active }) => (active && color ? color : COLORS.TIPPY_BACKGROUND)};

  &:hover {
    color: ${({ color }) => color || COLORS.TIPPY_BACKGROUND};
  }
`;

export const TabsWrapper = styled.div<{ isMenuCollapsed?: boolean }>`
  max-width: 48px;
  padding: 24px 0 10px 10px;
  overflow: visible;

  .header {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid ${COLORS.BORDERS};
    padding-bottom: 16px;
    margin-bottom: 16px;
    width: 32px;
    height: 32px;
    .circle {
      overflow: hidden;
      ${({ isMenuCollapsed }) =>
        isMenuCollapsed &&
        css`
          cursor: pointer;
        `}
    }
  }
`;

export const ItemsWrapper = styled.div`
  overflow-y: auto;
`;

export const ItemWrapper = styled.div`
  padding: 0 8px;
`;

export const Main = styled.div<{ isHidden?: boolean }>`
  width: 100%;
  height: calc(100vh - 64px - 100px);
  padding: 24px 24px 0;
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visibility')};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 28px;
  padding-bottom: 24px;

  .title {
    font-size: 18px;
    font-weight: 600;
    color: ${COLORS.TEXT_HOVER};
    white-space: nowrap;
  }
`;

export const ContentWrapper = styled.div`
  height: calc(100%);
  overflow-y: auto;

  .content {
    padding-bottom: 90px;
    min-width: 266px;
  }
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  padding-bottom: 10px;
  padding-top: 6px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 10px;
  line-height: 1.5;
  color: ${COLORS.LABEL_GRAY};
  background-color: ${COLORS.WHITE};

  * {
    white-space: nowrap;
  }
`;
