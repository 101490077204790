import React, { useCallback, useEffect, useState } from 'react';

import {
  CONFIRMATION_MODAL_TYPE,
  REVIEW_QUESTION_EVALUATORS,
  REVIEW_QUESTION_TYPES,
} from '@learned/constants';
import {
  type IConnection,
  type ILanguage,
  IReviewQuestion,
  IReviewQuestionCustomSkill,
  IReviewQuestionCustomSkillSettings,
  IReviewQuestionDefaultData,
  IReviewQuestionGoalPlanSettings,
  IReviewQuestionSkillCategorySettings,
  WithPartial,
  ISkillCategory,
} from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';

import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { GiveReview } from '~/pages/ReviewGiveFeedback/components/GiveReview';
import { PopulatedReviewTemplate } from '~/pages/ReviewTemplateView/types';
import {
  QuestionModal,
  type IQuestionModelProps,
} from '~/pages/ReviewThemeSetup/components/QuestionModal';
import { SkillModal } from '~/pages/ReviewThemeSetup/components/SkillModal';
import { type ISkill, IThemeQuestionDefaultData } from '~/pages/ReviewThemeSetup/types';
import { IQuestionForm, ISelectedSkill } from '~/pages/ReviewThemeSetup/types';
import {
  convertQuestionOptions,
  getEvaluators,
  getLabelsForAvailableLanguages,
  getRatingLabels,
} from '~/pages/ReviewThemeSetup/utils';

import { createFakeReview, createFakeReviewTask } from './mockData';

import useBoolState from '~/hooks/useBoolState';
import { type ILanguageStateReturn, useLanguageState } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getUser } from '~/selectors/baseGetters';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import { getSkillCategories } from '~/services/skillCategories';
import { getSkillsById } from '~/services/skills';
import { convertLanguageValue } from '~/utils/convertMultiLangValue';
import { getMultiLangString } from '~/utils/getMultiLangString';
import { turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

type GiveReviewPreviewProps = {
  template: PopulatedReviewTemplate;
  onClose: () => void;
  deleteQuestion?: (questionId: string) => void;
  updateQuestion: (
    question: Omit<WithPartial<IReviewQuestion, 'name' | 'type'>, 'company' | 'meta'>,
  ) => void;
  languageState: ILanguageStateReturn;
};

const GiveReviewPreview = ({
  template,
  onClose,
  deleteQuestion,
  updateQuestion,
  languageState,
}: GiveReviewPreviewProps) => {
  const multiLanguageState = useLanguageState(false);
  const currentUser = useSelector(getUser);
  const getString = useMultiLangString();
  const { i18n } = useLingui();
  const [skills, setSkills] = useState([]);
  const [skillModalSkills, setSkillModalSkills] = useState<ISkill[]>([]);
  const [loading, setLoading] = useState(true);
  const [editQuestion, setEditQuestion] = useState<IQuestionForm | null | undefined>(undefined);
  const [selectedTheme, setSelectedTheme] = useState<string | undefined>(undefined);
  const $isSkillModalOpen = useBoolState(false);
  const [skillCategories, setSkillCategories] = useState<
    Record<ISkillCategory['id'], ISkillCategory>
  >({});
  const [selectedSkills, setSelectedSkills] = useState<ISelectedSkill[]>([]);
  const [selectedSkill, setSelectedSkill] = useState<ISkill | null>(null);
  const [editedQuestionId, setEditedQuestionId] = useState<string | undefined>(undefined);

  const currentCompany = useSelector(getCurrentCompany);

  const fakeReview = createFakeReview(
    template,
    currentCompany,
    skills,
    skillCategories,
    i18n,
    getString,
  );
  const fakeReviewTasks = createFakeReviewTask(template, currentUser);

  // for <QuestionModal /> we need special structure of skill-categories
  const skillCategoriesForQuestionModal = Object.values(skillCategories).map((skillCategory) => {
    return {
      value: skillCategory.id,
      label: skillCategory.name,
      levels: skillCategory.skillLevels?.length,
      type: skillCategory.type,
    };
  }) as unknown as IQuestionModelProps['skillCategories'];

  useEffect(() => {
    const fetch = async () => {
      const customSkills: string[] = [];
      template.questions
        .filter((i) => i.type === REVIEW_QUESTION_TYPES.CUSTOM_SKILL)
        .map((i: IReviewQuestion) =>
          (i as IReviewQuestionCustomSkill).settings.skills.map((j) => customSkills.push(j.skill)),
        );
      const fetchedSkills = await getSkillsById(customSkills, ['focusAreas', 'categories']);
      setSkills(fetchedSkills);
      setLoading(false);
    };
    fetch();
  }, [template.questions]);

  useEffect(() => {
    const fetchSkillCategories = async () => {
      const response = await getSkillCategories();
      setSkillCategories(response);
    };

    fetchSkillCategories();
  }, [currentCompany]);

  const handleSubmit = (e: IQuestionForm) => {
    setLoading(true);
    const evaluators = Object.keys(e.settings.evaluators)
      .map((key: string) =>
        e.settings?.evaluators?.[key as keyof typeof e.settings.evaluators] ? key : null,
      )
      .filter(Boolean);
    const questionToEdit = {
      id: e.id as string,
      type: e.type.key as REVIEW_QUESTION_TYPES,
      theme: selectedTheme === 'OTHER' ? '' : (selectedTheme as string),
      name: convertLanguageValue(e.name),
      description: convertLanguageValue(e.description),
      settings: {
        options: e?.settings?.isManualScale ? getRatingLabels(languageState, e?.options) : null,
        evaluators,
        isCommentsAllowed: e.settings.isCommentsAllowed,
        isCommentsObligated: e.settings.isCommentsObligated,
        isAnswerObligated: !e.settings.isAnswerObligated,
        isMeasurementReversed: e.settings.isMeasurementReversed,
        isManualScale: e.settings.isManualScale,
        skillCategory:
          e.type.key === REVIEW_QUESTION_TYPES.SKILL_CATEGORY && e.skillOrKpiCategory?.id,
        skills: e.type.key === REVIEW_QUESTION_TYPES.CUSTOM_SKILL && e.settings.skills,
        subTypes: e.settings.subTypes,
      },
      ...(e.skillOrKpiCategory && {
        skillCategory: {
          id: e.skillOrKpiCategory.id,
          name: { en_GB: e.skillOrKpiCategory.value },
          type: e.skillOrKpiCategory.type,
        },
      }),
    };

    if (editQuestion) {
      updateQuestion(questionToEdit);
      setEditQuestion(undefined);
      setLoading(false);
    }
  };

  const handleDeleteFromQuestionModal = () => {
    if (editQuestion) {
      setLoading(true);
      deleteQuestion?.(editQuestion?.id || '');
      setEditQuestion(undefined);
      setLoading(false);
    }
  };

  const handleSelectedSkillSubmit = (selectedSkill: ISelectedSkill) => {
    setSelectedSkills([
      ...selectedSkills.filter(({ skill }: ISelectedSkill) => skill !== selectedSkill.skill),
      selectedSkill,
    ]);
    $isSkillModalOpen?.toggle();
  };

  const questionToEdit = async (questionId: string) => {
    const isConfirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.WARNING,
      title: i18n._(t`Be aware!`),
      description: i18n._(
        t`Changing this question will affect all review themes and templates that use this question.`,
      ),
    });
    if (!isConfirmed) {
      return;
    }
    setEditedQuestionId(questionId);
    const question = template.questions.find((q) =>
      q.type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY ||
      q.type === REVIEW_QUESTION_TYPES.CUSTOM_SKILL
        ? q.id === questionId.substring(0, questionId.lastIndexOf('-'))
        : q.id === questionId,
    );
    if (!question) {
      return;
    }
    const nameLabels = turnMultiLangIntoArray(question.name, multiLanguageState.companyLanguages);
    const descriptionLabels = turnMultiLangIntoArray(
      question.description || {},
      multiLanguageState.companyLanguages,
    );
    setEditQuestion({
      id: question.id,
      name: getLabelsForAvailableLanguages(multiLanguageState, nameLabels),
      description: getLabelsForAvailableLanguages(multiLanguageState, descriptionLabels),
      theme: question?.theme,
      // @ts-ignore
      type: question?.type,
      settings: {
        isCommentsAllowed:
          (question.settings as IThemeQuestionDefaultData)?.isCommentsAllowed ?? false,
        isCommentsObligated:
          (question.settings as IThemeQuestionDefaultData)?.isCommentsObligated ?? false,
        isMeasurementReversed:
          (question.settings as IThemeQuestionDefaultData)?.isMeasurementReversed ?? false,
        isManualScale: (question.settings as IThemeQuestionDefaultData)?.isManualScale ?? false,
        evaluators: getEvaluators(
          (question.settings as IThemeQuestionDefaultData)
            .evaluators as REVIEW_QUESTION_EVALUATORS[],
        ),
        skillCategory:
          (question.settings as IReviewQuestionSkillCategorySettings)?.skillCategory || '',
        skills: (question.settings as IReviewQuestionCustomSkillSettings)?.skills,
        isAnswerObligated:
          !(question.settings as IReviewQuestionDefaultData)?.isAnswerObligated || false,
        subTypes: (question.settings as IReviewQuestionGoalPlanSettings)?.subTypes || undefined,
      },
      options: convertQuestionOptions(question, multiLanguageState.companyLanguages),
    });
    if (question.type === REVIEW_QUESTION_TYPES.CUSTOM_SKILL) {
      setSelectedSkills(question.settings.skills);
    }
  };

  const previewLangString = useCallback(
    (multiLangString: Record<string, string> | string) => {
      if (!multiLangString) {
        return '';
      }
      const { languages, companyPrimaryLanguage } = languageState;
      const { locale } = languages[0];
      return multiLangString
        ? getMultiLangString(
            multiLangString,
            { preferredLang: { locale } as ILanguage } as IConnection,
            {
              primaryLang: companyPrimaryLanguage,
              languages,
            },
          )
        : '';
    },
    [languageState],
  );

  const multiLangString = () => (languageState ? previewLangString : useMultiLangString);

  const canDelete =
    fakeReview.questions.map((i) => i.id).filter((v, i, a) => a.indexOf(v) === i).length > 1;

  return (
    <ShowSpinnerIfLoading loading={loading}>
      {!loading && (
        <GiveReview
          onBack={onClose}
          isPreview
          // @ts-ignore
          reviewTask={fakeReviewTasks}
          // @ts-ignore
          userReview={fakeReview}
          introTitle={''}
          languageState={languageState}
          useMultiLangString={
            multiLangString as () => (multiLangString: string | Record<string, string>) => string
          }
          onEdit={(id) => questionToEdit(id)}
          editedQuestion={editedQuestionId}
        />
      )}
      {editQuestion && (
        <QuestionModal
          languageState={multiLanguageState}
          onClose={() => setEditQuestion(undefined)}
          onDelete={canDelete ? deleteQuestion && handleDeleteFromQuestionModal : undefined}
          onSubmit={handleSubmit}
          setIsSkillModalOpen={$isSkillModalOpen.set}
          skillCategories={skillCategoriesForQuestionModal}
          selectedSkills={selectedSkills}
          setSelectedSkills={setSelectedSkills}
          selectTheme={true}
          selectedQuestionToEdit={editQuestion}
          selectedTheme={selectedTheme ?? null}
          hidePreview
          setSelectedTheme={setSelectedTheme}
          // @ts-ignore
          setPreviewQuestion={questionToEdit}
        />
      )}
      {$isSkillModalOpen.value && (
        <SkillModal
          onClose={() => $isSkillModalOpen.off()}
          onSubmit={handleSelectedSkillSubmit}
          setSelectedSkill={setSelectedSkill}
          selectedSkill={selectedSkill}
          skills={skillModalSkills}
          setSkills={setSkillModalSkills}
          selectedSkills={selectedSkills}
        />
      )}
    </ShowSpinnerIfLoading>
  );
};

export { GiveReviewPreview };
