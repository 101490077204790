import type { ReactNode } from 'react';

import { ICONS } from '../Icon';

import type { IUser } from '@learned/types';

export enum DashboardDrawerMenuKey {
  CAREER = 'career',
  GOALS = 'goals',
  USER_REVIEWS = 'userReviews',
  NOTES = 'notes',
}

export interface IMenuItem {
  key: DashboardDrawerMenuKey;
  title?: string;
  icon: ICONS;
  content?: ReactNode;
  color: string;
  isLoading: boolean;
}

export interface DashboardDrawerProps {
  user?: IUser;
  menuItems?: IMenuItem[];
  position?: {
    top?: number;
    right?: number;
  };
}
