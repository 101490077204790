import React from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import ToolTip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';
import { FocusAreaRating } from '~/pages/ReviewGiveFeedback/components/Questions/Components/FocusAreaRating';
import { FocusAreaRatings } from '~/pages/ReviewGiveFeedback/components/Questions/Components/FocusAreaRating.design';
import PreviousAnswerLegend from '~/pages/ReviewGiveFeedback/components/Questions/Components/PreviousAnswerLegend';
import {
  CareerHeader,
  FocusAreaHeader,
  IconBackground,
  IconContainer,
  JobNameBar,
  Scale,
} from '~/pages/ReviewGiveFeedback/components/Questions/design';
import { getSkillWithPopulatedSkillCategory } from '~/pages/ReviewGiveFeedback/components/Questions/SkillQuestion/utils';

import { COLORS } from '~/styles';

import type { IQuestionViewSkillProps, ISkillQuestionForm } from '.';
import type {
  IReviewQuestionSkillCategorySettings,
  ISkill,
  IUserReviewPopulated,
} from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

const JobFocusAreasWrapper = styled.div`
  margin-bottom: 24px;
`;

interface IJobFocusAreasProps {
  previousRatings: IQuestionViewSkillProps['previousRatings'];
  focusAreaQuestions: IQuestionViewSkillProps['focusAreaQuestions'];
  hasError?: boolean;
  form: UseFormReturn<ISkillQuestionForm>;
  onChange: IQuestionViewSkillProps['onChange'];
  jobProfileName?: string;
  notApplicableWatch?: boolean;
  options?: IReviewQuestionSkillCategorySettings['options'];
  focusAreaQuestionFirstIndex: number;
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
  openSkillModal: (skill: ISkill['id'], expandLevels: number[]) => void;
  userReview?: IUserReviewPopulated;
}

export const FocusAreasList = ({
  jobProfileName,
  previousRatings,
  options,
  focusAreaQuestions,
  hasError,
  form,
  onChange,
  notApplicableWatch,
  focusAreaQuestionFirstIndex,
  useMultiLangString, // we need to use this only from props, because for outside review -> hook will not work
  openSkillModal,
  userReview,
}: IJobFocusAreasProps) => {
  const getMultiLangString = useMultiLangString();

  return (
    <JobFocusAreasWrapper>
      <JobNameBar>
        <div>
          {jobProfileName && (
            <CareerHeader marginBottom="0px">
              <IconContainer>
                <IconBackground />
                <Icon size={ICON_SIZES.SMALL} icon={ICONS.CAREER} color={COLORS.COMPANY} />
              </IconContainer>
              <div>{jobProfileName}</div>
            </CareerHeader>
          )}
        </div>
        {/* show the previous answer legend when only previous answers are there */}
        {Object.keys(previousRatings ?? {}).length > 0 && <PreviousAnswerLegend />}
      </JobNameBar>

      <FocusAreaRatings>
        {options && (
          <FocusAreaHeader>
            <div>
              <Trans>Expected behavior or result</Trans>
            </div>
            <Scale>
              <ToolTip
                maxWidth="300px"
                placement={TOOLTIP_PLACEMENTS.BOTTOM}
                tooltip={`1 - ${getMultiLangString(options[0].label)}`}
              >
                <div>1 - {getMultiLangString(options[0].label)}</div>
              </ToolTip>
              <ToolTip
                maxWidth="300px"
                placement={TOOLTIP_PLACEMENTS.BOTTOM}
                tooltip={`${options.length} - ${getMultiLangString(
                  options.at(options.length - 1)!.label,
                )}`}
              >
                <div>
                  {options.length} - {getMultiLangString(options.at(options.length - 1)!.label)}
                </div>
              </ToolTip>
            </Scale>
          </FocusAreaHeader>
        )}
        {focusAreaQuestions.map((fa, questionIndex) => {
          const previousRating = previousRatings?.latestPerUserReviewQuestion?.[fa.question.id];
          const skillId = fa.question.settings.skill;

          // We add focusAreaQuestionFirstIndex to maintain continuous numbering across multiple job profiles,
          // since each job profile starts its own focusAreaQuestions array from 0
          const index = questionIndex + focusAreaQuestionFirstIndex;

          return (
            <FocusAreaRating
              useMultiLangString={useMultiLangString}
              hasError={hasError}
              index={index}
              key={fa.question.id}
              focusAreaQuestion={fa}
              form={form}
              onChange={onChange}
              disabled={notApplicableWatch}
              previousRating={previousRating}
              openSkillModal={openSkillModal}
              skill={getSkillWithPopulatedSkillCategory(skillId, userReview)}
            />
          );
        })}
      </FocusAreaRatings>
    </JobFocusAreasWrapper>
  );
};
