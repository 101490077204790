import React, { useEffect, useMemo, useState } from 'react';

import {
  REVIEW_QUESTION_EVALUATORS,
  REVIEW_QUESTION_TYPES,
  REVIEW_RATING_TYPE,
} from '@learned/constants';
import { Trans } from '@lingui/macro';
import _, { isEmpty } from 'lodash';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import { SkillModal } from '~/components/Modals/SkillModal';
import type { IQuestionDefaultData } from '~/pages/ReviewGiveFeedback/types';
import type {
  IUserReviewQuestionCustomSkillGrouped,
  IUserReviewQuestionSkillCategoryGrouped,
} from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/utils';

import {
  CoachCircle,
  FocusAreaHeader,
  PeerCircle,
  RatingHeader,
  SelfCircle,
  Table,
  TableRow,
} from './design';
import { JobNameAndLabels } from './JobNameAndLabels';
import { SkillAnswersModal } from './SkillAnswersModal';
import { SkillRow } from './SkillRow';
import { getSortedJobs } from './utils';

import useBoolState from '~/hooks/useBoolState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getSkill } from '~/services/skills';

import type {
  IJobProfile,
  IReviewRating,
  IReviewTheme,
  ISkill,
  ISkillCategory,
  IUserReview,
  IUserReviewQuestionSkillCategoryWithJobProfile,
  WithExtends,
  WithReplace,
} from '@learned/types';

type TQuestionLocal = IUserReviewQuestionSkillCategoryWithJobProfile & {
  reviewRatings?: IReviewRating[];
};

function SkillAnswers({
  onEdit,
  question,
  isZeroState,
  userReview,
  questionNumber,
  isPDFView = false,
}: {
  onEdit: (
    skill:
      | IUserReviewQuestionSkillCategoryGrouped['skills'][0]
      | IUserReviewQuestionCustomSkillGrouped['skills'][0],
    question: IQuestionDefaultData,
  ) => void;
  isZeroState: boolean;
  questionNumber: number;
  question: WithExtends<
    IUserReviewQuestionSkillCategoryGrouped | IUserReviewQuestionCustomSkillGrouped,
    { theme?: IReviewTheme; jobProfiles?: IJobProfile[] }
  >;
  userReview: IUserReview;
  isPDFView?: boolean;
}) {
  const getMultiLangString = useMultiLangString();

  const [availableEvaluators, setAvailableEvaluators] = useState({
    employee: true,
    coach: true,
    peer: true,
  });
  const [showCoachesModal, setShowCoachesModal] = useState(false);
  const [showPeersModal, setShowPeersModal] = useState(false);
  const isLoadingSkill = useBoolState(false);
  const [skillModalId, setSkillModalId] = useState<string>('');
  const [skillModal, setSkillModal] =
    useState<WithReplace<ISkill, { skillCategory: ISkillCategory }>>();

  const isSkillCategoryQuestion = question?.type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY;

  // define primary job profile (if exists)
  const primaryJobProfileId = _.get(
    _.find(_.values(_.get(userReview, 'backup.careerPlans', {})), 'primary'),
    'jobProfile',
  );

  const jobs = isSkillCategoryQuestion
    ? getSortedJobs(
        (
          question as WithExtends<
            IUserReviewQuestionSkillCategoryGrouped,
            { theme?: IReviewTheme; jobProfiles?: IJobProfile[] }
          >
        ).jobProfiles || [],
        getMultiLangString,
        primaryJobProfileId,
      )
    : [];

  const relevantQuestion = question?.skills?.at(0)?.questions?.at(0);
  const options = relevantQuestion?.settings.options;

  // we filter skills and focus-areas in skills per job
  const jobsWithSkills = isSkillCategoryQuestion
    ? jobs.map((jobProfile) => {
        return {
          jobProfile,
          skills: question.skills
            .map((item) => {
              const questionsInJobProfile = (item.questions as unknown as TQuestionLocal[]).filter(
                // @ts-ignore
                (question) => question?.settings?.jobProfile?.id === jobProfile.id,
              );
              return {
                skillId: item.skillId,
                questions: questionsInJobProfile,
              };
            })
            .filter((item) => !isEmpty(item.questions)),
        };
      })
    : [
        {
          jobProfile: {} as IJobProfile, // custom-skill does not have jobProfile
          skills: question.skills,
        },
      ];

  const totalCoaches = useMemo(() => {
    const ratings = question.skills.flatMap((skill) =>
      skill.questions.flatMap((q) =>
        (q.reviewRatings ?? []).filter((rating) => rating.type === REVIEW_RATING_TYPE.COACH),
      ),
    );
    const uniqueCoaches = new Set(
      ratings.map((rating) => rating.createdBy.id ?? rating.createdBy.email),
    );
    return uniqueCoaches.size;
  }, [question]);

  const totalPeers = useMemo(() => {
    const ratings = question.skills.flatMap((skill) =>
      skill.questions.flatMap((q) =>
        (q.reviewRatings ?? []).filter(
          (rating) =>
            rating.type === REVIEW_RATING_TYPE.PEER ||
            rating.type === REVIEW_RATING_TYPE.PEER_EMAIL,
        ),
      ),
    );
    const uniqueCoaches = new Set(
      ratings.map((rating) => rating.createdBy.id ?? rating.createdBy.email),
    );
    return uniqueCoaches.size;
  }, [question]);

  useEffect(() => {
    const evaluators = {
      employee: true,
      coach: true,
      peer: true,
    };
    Object.values(REVIEW_QUESTION_EVALUATORS).forEach((key) => {
      evaluators[key] = !!question?.skills?.some((skill: any) =>
        skill.questions?.[0]?.settings.evaluators.includes(key),
      );
    });
    setAvailableEvaluators(evaluators);
  }, [question?.skills]);

  const fetchSkill = async (skillId: string) => {
    const res = await getSkill(skillId, ['skillCategory']);
    if (res?.data?.skill) {
      setSkillModal(res.data.skill);
      isLoadingSkill.off();
    }
  };

  useEffect(() => {
    if (skillModalId) {
      isLoadingSkill.on();
      fetchSkill(skillModalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillModalId]);

  return (
    <>
      {jobsWithSkills.map(({ jobProfile, skills: skillsFromJobProfile }, index) => (
        <div key={jobProfile.id || 'default'}>
          <JobNameAndLabels
            isZeroState={isZeroState}
            isMarginTop={Boolean(index)}
            options={options}
            jobName={getMultiLangString(jobProfile?.name ?? '')}
          />
          <Table $isZeroState={isZeroState}>
            <TableRow noPageBreakAfter>
              <FocusAreaHeader>
                <Trans>Behavior and results</Trans>
              </FocusAreaHeader>
              {availableEvaluators[REVIEW_QUESTION_EVALUATORS.EMPLOYEE] && (
                <RatingHeader>
                  {!isZeroState && <SelfCircle />}
                  <Trans>SELF</Trans>
                </RatingHeader>
              )}
              {availableEvaluators[REVIEW_QUESTION_EVALUATORS.COACH] && (
                <RatingHeader>
                  {!isZeroState && <CoachCircle />}
                  <Trans>COACH</Trans>
                  {!isZeroState && totalCoaches > 1 && (
                    <>
                      <span>({totalCoaches})</span>{' '}
                      {!isPDFView && (
                        <Icon
                          icon={ICONS.EXTERNAL_LINK}
                          size={ICON_SIZES.SMALL}
                          onClick={() => setShowCoachesModal(true)}
                        />
                      )}
                    </>
                  )}
                </RatingHeader>
              )}
              {availableEvaluators[REVIEW_QUESTION_EVALUATORS.PEER] && (
                <RatingHeader>
                  {!isZeroState && <PeerCircle />}
                  <Trans>PEERS</Trans>
                  {!isZeroState && totalPeers > 1 && (
                    <>
                      <span>({totalPeers})</span>{' '}
                      {!isPDFView && (
                        <Icon
                          icon={ICONS.EXTERNAL_LINK}
                          size={ICON_SIZES.SMALL}
                          onClick={() => setShowPeersModal(true)}
                        />
                      )}
                    </>
                  )}
                </RatingHeader>
              )}
            </TableRow>
            {skillsFromJobProfile.map((skill) => (
              <SkillRow
                availableEvaluators={availableEvaluators}
                key={skill.skillId}
                skill={skill}
                userReview={userReview}
                isPDFView={isPDFView}
                onSkillClick={() => setSkillModalId(skill.skillId)}
                onEdit={(ratings: IReviewRating[]) =>
                  onEdit(
                    {
                      ...skill,
                      // @ts-ignore
                      reviewRating: ratings,
                      type: relevantQuestion?.type,
                    },
                    question,
                  )
                }
              />
            ))}
          </Table>
        </div>
      ))}

      {showCoachesModal && (
        <SkillAnswersModal
          key="coaches"
          themeName={getMultiLangString((question.theme as IReviewTheme)?.name)}
          questionNumber={questionNumber}
          userReview={userReview}
          question={question}
          jobsWithSkills={jobsWithSkills}
          onClose={() => setShowCoachesModal(false)}
          ratingFilter={(rating?: IReviewRating) => rating?.type === REVIEW_RATING_TYPE.COACH}
          isZeroState={isZeroState}
          options={options}
          header={
            <>
              <CoachCircle />
              <Trans>COACHES</Trans>
              <span>({totalCoaches})</span>
            </>
          }
          onEdit={(
            skill: {
              skillId: string;
              questions: (IUserReviewQuestionSkillCategoryWithJobProfile & {
                reviewRatings?: IReviewRating[];
              })[];
            },
            ratings: IReviewRating[],
            question: IQuestionDefaultData,
          ) =>
            onEdit(
              {
                ...skill,
                // @ts-ignore
                reviewRating: ratings,
                type: relevantQuestion?.type,
              },
              question,
            )
          }
        />
      )}
      {showPeersModal && (
        <SkillAnswersModal
          key="peers"
          themeName={getMultiLangString((question.theme as IReviewTheme)?.name)}
          questionNumber={questionNumber}
          userReview={userReview}
          question={question}
          jobsWithSkills={jobsWithSkills}
          onClose={() => setShowPeersModal(false)}
          ratingFilter={(rating?: IReviewRating) =>
            rating?.type === REVIEW_RATING_TYPE.PEER ||
            rating?.type === REVIEW_RATING_TYPE.PEER_EMAIL
          }
          isZeroState={isZeroState}
          options={options}
          header={
            <>
              <PeerCircle />
              <Trans>PEERS</Trans>
              <span>({totalPeers})</span>
            </>
          }
          onEdit={(
            skill: {
              skillId: string;
              questions: (IUserReviewQuestionSkillCategoryWithJobProfile & {
                reviewRatings?: IReviewRating[];
              })[];
            },
            ratings: IReviewRating[],
            question: IQuestionDefaultData,
          ) =>
            onEdit(
              {
                ...skill,
                // @ts-ignore
                reviewRating: ratings,
                type: relevantQuestion?.type,
              },
              question,
            )
          }
        />
      )}
      {skillModalId && (
        <SkillModal
          skill={skillModal}
          onClose={() => {
            setSkillModal(undefined);
            setSkillModalId('');
          }}
          isLoading={isLoadingSkill.value}
        />
      )}
    </>
  );
}

export { SkillAnswers };
