import _ from 'lodash';

import type { IJobProfile, IMultiLangString } from '@learned/types';

export const getSortedJobs = (
  jobProfiles: IJobProfile[],
  getMultiLangString: (multiLangString: IMultiLangString) => string,
  primaryJobProfileId?: string,
) => {
  return _.sortBy(jobProfiles, [
    (jobProfile) => jobProfile.id !== primaryJobProfileId, // Primary job first
    (jobProfile) => getMultiLangString(jobProfile.name), // Then A → Z
  ]);
};
