import React, { useState } from 'react';

import { Trans } from '@lingui/macro';

import { ICONS, ICON_SIZES } from '~/components/Icon';

import {
  Count,
  Level,
  FocusArea,
  LevelName,
  StyledIcon,
  StyledLevelHeader,
} from './FocusAreas.design';

import { useMultiLangString } from '~/hooks/useMultiLangString';

import type { IMultiLangString } from '@learned/types';

function FocusAreas({
  levelName,
  focusAreas,
  isExpandedByDefault = false,
  useMultiLangString: useMultiLangStringFromProps,
}: {
  focusAreas: {
    level: number;
    values: { id: string; name: IMultiLangString }[];
  };
  levelName: IMultiLangString;
  isExpandedByDefault?: boolean;
  useMultiLangString?: () => (multiLangString: Record<string, string> | string) => string; // for outside review we need to path this as props because company is not defined
}) {
  // here we define if we get hook from props or import
  const useMultiLangStringCustom = useMultiLangStringFromProps
    ? useMultiLangStringFromProps
    : useMultiLangString;

  const getMultiLangString = useMultiLangStringCustom();
  const [opened, setOpened] = useState(isExpandedByDefault);

  return (
    <Level>
      <StyledLevelHeader onClick={() => setOpened((prev) => !prev)}>
        <LevelName>{getMultiLangString(levelName)}</LevelName>
        <Count>
          <Trans>{focusAreas.values.length} focus area&apos;s</Trans>
          <StyledIcon icon={ICONS.DROPDOWN} size={ICON_SIZES.SMALL} $opened={opened} />
        </Count>
      </StyledLevelHeader>
      {opened && (
        <>
          {focusAreas.values.map((focusArea) => (
            <FocusArea key={focusArea.id}>{getMultiLangString(focusArea.name)}</FocusArea>
          ))}
        </>
      )}
    </Level>
  );
}

export { FocusAreas };
