import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import CheckBox from '~/components/CheckBox';
import {
  Header,
  Heading,
  Title,
  Subtitle,
  Footer,
  StyledModal,
  StyledTableList,
  StyledCloseButton,
  CheckBoxContainer,
} from '~/components/SearchSelectModal/design';
import { ISearchSelectModalProps } from '~/components/SearchSelectModal/types';

import { COLORS } from '~/styles';

function SearchSelectModal({
  title,
  subtitle,
  primaryActionLabel,
  tableListProps,
  onClose,
  onPrimaryAction,
  disablePrimary,
  showCancel = true,
  className,
  isSingleSelect,
  isCheckboxVisible,
  checkboxProps,
}: ISearchSelectModalProps) {
  const { i18n } = useLingui();

  return (
    <StyledModal
      ignoreModalStyle
      showDivider={false}
      hideFooter
      isHideHeader
      onClose={onClose}
      className={className}
    >
      <Header>
        <Heading>
          <Title>{title}</Title>
          <StyledCloseButton
            label={''}
            variant={ButtonVariant.CLOSE}
            size={ButtonSize.MEDIUM}
            onClick={onClose}
            color={COLORS.TOOLTIP_BACKGROUND}
          />
        </Heading>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </Header>
      <StyledTableList
        {...tableListProps}
        multiSelectProps={{
          isMultiSelectVisible: true,
          multiSelect: tableListProps.multiSelectProps.multiSelect,
          ...(isSingleSelect && {
            isSelectedCountVisible: false,
            isSelectAllVisible: false,
            isSingleSelect,
          }),
        }}
        topClassName="select-modal-table-top"
      />
      {isCheckboxVisible && (
        <CheckBoxContainer>
          <CheckBox
            size={20}
            checked={checkboxProps?.checked}
            onChange={checkboxProps?.onChange}
            isSecondary={true}
          />
          <span>{checkboxProps?.label}</span>
        </CheckBoxContainer>
      )}
      <Footer>
        {showCancel && (
          <Button
            label={i18n._(t`Cancel`)}
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
            onClick={onClose}
          />
        )}
        <Button
          disabled={disablePrimary}
          label={primaryActionLabel}
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.MEDIUM}
          onClick={onPrimaryAction}
        />
      </Footer>
    </StyledModal>
  );
}

export { SearchSelectModal };
