import React from 'react';

import styled from 'styled-components';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import { FocusAreaAnswers } from '~/pages/ReviewGiveFeedback/components/Questions/Components/FocusAreaAnswers';
import {
  Answers,
  CareerHeader,
  IconBackground,
  IconContainer,
} from '~/pages/ReviewGiveFeedback/components/Questions/design';
import type { IQuestionSkillCategorySubQuestion } from '~/pages/ReviewGiveFeedback/types';

import { COLORS } from '~/styles';

const JobNameBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 12px 0;
`;

interface IFocusAreaAnswersPerJobProps {
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
  questionsWithAnswersInJob: IQuestionSkillCategorySubQuestion[];
  jobProfileNameString?: string;
}

export const FocusAreaAnswersPerJob = ({
  useMultiLangString,
  jobProfileNameString,
  questionsWithAnswersInJob,
}: IFocusAreaAnswersPerJobProps) => {
  return (
    <div>
      {jobProfileNameString && (
        <JobNameBar>
          <div>
            <CareerHeader marginBottom="0px">
              <IconContainer>
                <IconBackground />
                <Icon size={ICON_SIZES.SMALL} icon={ICONS.CAREER} color={COLORS.COMPANY} />
              </IconContainer>
              <div>{jobProfileNameString}</div>
            </CareerHeader>
          </div>
        </JobNameBar>
      )}
      <Answers>
        {(questionsWithAnswersInJob || []).map((faQuestion, index) => {
          return (
            <FocusAreaAnswers
              useMultiLangString={useMultiLangString}
              key={index}
              focusAreaQuestion={faQuestion}
            />
          );
        })}
      </Answers>
    </div>
  );
};
