import React, { useMemo } from 'react';

import { REVIEW_RATING_TYPE } from '@learned/constants';
import { Trans } from '@lingui/macro';

import { StyledRickTextView } from '~/components/SurveyQuestionComments/design';
import { UserAvatar } from '~/components/UserAvatar';

import { Comment, Rating, User } from './FocusAreaComments.design';

import { Comments, CommentsContainer } from '../../Questions/design';

import type {
  IPartialReviewRating,
  IQuestionCustomSkillData,
  IQuestionSkillCategoryData,
} from '../../../types';
import type { IUser } from '@learned/types';

function FocusAreaComments({
  focusAreaQuestion,
  useMultiLangString,
}: {
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
  focusAreaQuestion?:
    | IQuestionCustomSkillData['subQuestions'][0]
    | IQuestionSkillCategoryData['subQuestions'][0];
}) {
  const getMultiLangString = useMultiLangString();
  const allRatings = useMemo(() => {
    return [
      ...(focusAreaQuestion?.otherCoachRatings ?? []),
      ...(focusAreaQuestion?.otherPeerRatings ?? []),
      focusAreaQuestion?.otherSelfRating,
    ].filter((rating) => rating && rating.comment) as IPartialReviewRating[];
  }, [focusAreaQuestion]);

  return (
    <>
      {allRatings.length > 0 && (
        <CommentsContainer>
          <Trans>Comments</Trans>
          <Comments>
            {allRatings?.map((rating) => (
              <Rating key={rating.id}>
                <User>
                  {rating.createdBy.id ? (
                    <UserAvatar userId={rating.createdBy.id} />
                  ) : (
                    <UserAvatar user={{ email: rating.createdBy.email! } as IUser} />
                  )}{' '}
                  <span>
                    {rating.type === REVIEW_RATING_TYPE.COACH && (
                      <>
                        (<Trans>Coach</Trans>)
                      </>
                    )}
                    {(rating.type === REVIEW_RATING_TYPE.PEER ||
                      rating.type === REVIEW_RATING_TYPE.PEER_EMAIL) && (
                      <>
                        (<Trans>Peer</Trans>)
                      </>
                    )}
                    {rating.type === REVIEW_RATING_TYPE.SELF && (
                      <>
                        (<Trans>Self</Trans>)
                      </>
                    )}
                  </span>
                </User>
                <Comment>
                  <StyledRickTextView html={getMultiLangString(rating.comment || '')} />
                </Comment>
              </Rating>
            ))}
          </Comments>
        </CommentsContainer>
      )}
    </>
  );
}

export { FocusAreaComments };
