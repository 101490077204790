import get from 'lodash/get';

export const getCurrentConversation = (state) => state.currentConversation;
export const getName = (state) => state.currentConversation.name;
export const getId = (state) => state.currentConversation.id;
export const getStatus = (state) => state.currentConversation.status;
export const getDescription = (state) => state.currentConversation.description;
export const getRepeat = (state) => get(state, 'currentConversation.repeat');
export const getStartDate = (state) => get(state, 'currentConversation.startDate');
export const getEndDate = (state) => get(state, 'currentConversation.endDate');
export const getParticipants = (state) => get(state, 'currentConversation.participants');
export const getSubject = (state) => get(state, 'currentConversation.subject');
export const getCreator = (state) => get(state, 'currentConversation.createdBy');
export const getTalkingPoints = (state) => get(state, 'currentConversation.talkingPoints');
export const getCreatedBy = (state) => get(state, 'currentConversation.createdBy');
