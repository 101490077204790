import React from 'react';

import { REVIEW_RATING_TYPE, REVIEW_QUESTION_TYPES } from '@learned/constants';
import {
  IAllPreviousRatingResponse,
  IReviewRating,
  IUserReview,
  IUserReviewQuestionText,
  IUserReviewQuestionRating,
  IMultiLangString,
} from '@learned/types';

import useBoolState from '~/hooks/useBoolState';
import { getPreviousReviewRatingsForReviewQuestion } from '~/services/userReviews';

import {
  IPartialReviewRating,
  IQuestionCustomSkillData,
  IQuestionSkillCategoryData,
  TPreviousUserReviewWithQuestionAndAnswers,
  TPreviousUserReviewsWithAnswerPerReviewQuestion,
} from '../types';

interface IUseAllPreviousAnswersProps {
  userReviewId: string;
}

type TUserReviewQuestion =
  | IUserReviewQuestionText
  | IUserReviewQuestionRating
  | IQuestionCustomSkillData['subQuestions'][number]['question']
  | IQuestionSkillCategoryData['subQuestions'][number]['question'];

const getReviewInfo = (
  userReview: Partial<IUserReview>,
): { reviewId: string; reviewName: IMultiLangString; reviewStartDate: Date } => {
  return {
    reviewId: userReview.id!,
    reviewName: userReview.name!,
    reviewStartDate: new Date(userReview.settings?.startDate || ''),
  };
};

const findRating = (
  response: IAllPreviousRatingResponse,
  userReviewQuestionId: string,
  reviewerType: REVIEW_RATING_TYPE[],
) => {
  return response.ratingsPerUserReviewQuestion[userReviewQuestionId]?.find((r: any) =>
    reviewerType.includes(r.type),
  ) as IPartialReviewRating | undefined;
};

const findRatings = (
  response: IAllPreviousRatingResponse,
  userReviewQuestionId: string,
  reviewerType: REVIEW_RATING_TYPE[],
) => {
  return (
    (response.ratingsPerUserReviewQuestion[userReviewQuestionId]?.filter((r: any) =>
      reviewerType.includes(r.type),
    ) as IPartialReviewRating[]) || ([] as IPartialReviewRating[])
  );
};

/**
 * Transforms the response IAllPreviousRatingResponse to match the answer components
 * @param data response from api
 * @param userReviewQuestions the user review question that the user is currently answering
 * @returns transformed object
 */
const transformPreviousRatingData = (
  data: IAllPreviousRatingResponse,
  userReviewQuestions: TUserReviewQuestion[],
): TPreviousUserReviewWithQuestionAndAnswers<REVIEW_QUESTION_TYPES> => {
  const questionType = userReviewQuestions[0].type;

  if (
    questionType === REVIEW_QUESTION_TYPES.RATING ||
    questionType === REVIEW_QUESTION_TYPES.TEXT
  ) {
    const previousUserReviewWithQuestionAndAnswers: TPreviousUserReviewWithQuestionAndAnswers<
      REVIEW_QUESTION_TYPES.RATING | REVIEW_QUESTION_TYPES.TEXT
    > = {
      ...getReviewInfo(data.userReview),
      type: questionType as REVIEW_QUESTION_TYPES.RATING | REVIEW_QUESTION_TYPES.TEXT,
      questionWithAnswers: {
        otherCoachRatings: [] as IPartialReviewRating[],
        otherSelfRating: {} as IPartialReviewRating,
        otherPeerRatings: [] as IPartialReviewRating[],
        yourRating: {} as IReviewRating, // DULARA this is unnecessary, been required the by type
      },
    };

    // for rating and text questions
    userReviewQuestions.forEach(({ id: userReviewQuestionId }) => {
      previousUserReviewWithQuestionAndAnswers.questionWithAnswers.otherCoachRatings?.push(
        ...findRatings(data, userReviewQuestionId, [REVIEW_RATING_TYPE.COACH]),
      );
      previousUserReviewWithQuestionAndAnswers.questionWithAnswers.otherPeerRatings?.push(
        ...findRatings(data, userReviewQuestionId, [
          REVIEW_RATING_TYPE.PEER,
          REVIEW_RATING_TYPE.PEER_EMAIL,
        ]),
      );
      previousUserReviewWithQuestionAndAnswers.questionWithAnswers.otherSelfRating =
        findRating(data, userReviewQuestionId, [REVIEW_RATING_TYPE.SELF]) || undefined;
    });

    return previousUserReviewWithQuestionAndAnswers;
  } else if (questionType === REVIEW_QUESTION_TYPES.SKILL_CATEGORY) {
    const previousUserReviewWithQuestionAndAnswers: TPreviousUserReviewWithQuestionAndAnswers<REVIEW_QUESTION_TYPES.SKILL_CATEGORY> =
      {
        ...getReviewInfo(data.userReview),
        type: REVIEW_QUESTION_TYPES.SKILL_CATEGORY,
        questionsWithAnswers: [],
      };
    userReviewQuestions.forEach(({ id: userReviewQuestionId }) => {
      const question = userReviewQuestions.find(
        (q) => q.id === userReviewQuestionId,
      ) as IQuestionSkillCategoryData['subQuestions'][number]['question'];
      if (!question) {
        return;
      }
      previousUserReviewWithQuestionAndAnswers.questionsWithAnswers?.push({
        question,
        yourRating: {} as IReviewRating, // DULARA this is unnecessary, been required the by type
        otherCoachRatings: findRatings(data, userReviewQuestionId, [REVIEW_RATING_TYPE.COACH]),
        otherPeerRatings: findRatings(data, userReviewQuestionId, [
          REVIEW_RATING_TYPE.PEER,
          REVIEW_RATING_TYPE.PEER_EMAIL,
        ]),
        otherSelfRating: findRating(data, userReviewQuestionId, [REVIEW_RATING_TYPE.SELF]),
      });
    });
    return previousUserReviewWithQuestionAndAnswers;
  } else if (questionType === REVIEW_QUESTION_TYPES.CUSTOM_SKILL) {
    const previousUserReviewWithQuestionAndAnswers: TPreviousUserReviewWithQuestionAndAnswers<REVIEW_QUESTION_TYPES.CUSTOM_SKILL> =
      {
        ...getReviewInfo(data.userReview),
        type: REVIEW_QUESTION_TYPES.CUSTOM_SKILL,
        questionsWithAnswers: [],
      };
    userReviewQuestions.forEach(({ id: userReviewQuestionId }) => {
      const question = userReviewQuestions.find(
        (q) => q.id === userReviewQuestionId,
      ) as IQuestionCustomSkillData['subQuestions'][number]['question'];
      if (!question) {
        return;
      }
      previousUserReviewWithQuestionAndAnswers.questionsWithAnswers?.push({
        question,
        yourRating: {} as IReviewRating, // DULARA this is unnecessary, been required the by type
        otherCoachRatings: findRatings(data, userReviewQuestionId, [REVIEW_RATING_TYPE.COACH]),
        otherPeerRatings: findRatings(data, userReviewQuestionId, [
          REVIEW_RATING_TYPE.PEER,
          REVIEW_RATING_TYPE.PEER_EMAIL,
        ]),
        otherSelfRating: findRating(data, userReviewQuestionId, [REVIEW_RATING_TYPE.SELF]),
      });
    });
    return previousUserReviewWithQuestionAndAnswers;
  } else {
    return {} as TPreviousUserReviewWithQuestionAndAnswers<REVIEW_QUESTION_TYPES>;
  }
};

const useAllPreviousAnswers = ({ userReviewId }: IUseAllPreviousAnswersProps) => {
  const [previousQuestionsAnswers, setPreviousQuestionsAnswers] =
    React.useState<TPreviousUserReviewsWithAnswerPerReviewQuestion>({});
  const $loading = useBoolState(false);

  const preparePreviousAnswers = (
    reviewQuestionId: string,
    userReviewQuestions: TUserReviewQuestion[],
    data: Record<string, Record<string, IAllPreviousRatingResponse[]>>,
    skillId?: string,
  ) => {
    setPreviousQuestionsAnswers((prev) => {
      // this is used for skill questions only
      const compositeKey = skillId ? `${reviewQuestionId}:${skillId}` : reviewQuestionId;

      const previousQuestionData = prev[compositeKey];

      // this can grow, but only one entry now.
      const fetchedQuestionData = data.allPerReviewQuestion[reviewQuestionId]?.[0];

      return {
        ...prev,
        // for skill questions, to identify entries uniquely, lets combine results
        [skillId ? `${reviewQuestionId}:${skillId}` : reviewQuestionId]: {
          ...previousQuestionData,
          ...{
            userReviews: [
              ...(previousQuestionData?.userReviews || []),
              ...(fetchedQuestionData
                ? [transformPreviousRatingData(fetchedQuestionData, userReviewQuestions)]
                : []),
            ],
          },
          isAllPreviousReviewsFetched: fetchedQuestionData ? false : true,
        },
      };
    });
  };

  const fetchData = async (
    reviewQuestionId: string,
    userReviewQuestions: TUserReviewQuestion[],
    reviewStartedBefore?: Date,
    skillId?: string, // skill id is only used for skill category/ custom skill type questions
  ) => {
    if ($loading.value || previousQuestionsAnswers[reviewQuestionId]?.isAllPreviousReviewsFetched) {
      return;
    }

    $loading.on();

    const previousReviewAnswers = await getPreviousReviewRatingsForReviewQuestion(
      userReviewId,
      reviewQuestionId,
      reviewStartedBefore,
      skillId,
    );

    preparePreviousAnswers(
      reviewQuestionId,
      userReviewQuestions,
      previousReviewAnswers.data,
      skillId,
    );

    $loading.off();
  };

  return {
    previousQuestionsAnswers,
    getFetchedAnswers: (questionId: string) => previousQuestionsAnswers[questionId],
    fetchData,
    isLoading: $loading.value,
  };
};

export { useAllPreviousAnswers };
