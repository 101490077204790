import React from 'react';

import { IMultiLangString } from '@learned/types';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import {
  IconBackground,
  IconContainer,
} from '~/pages/ReviewGiveFeedback/components/Questions/design';
import {
  Header,
  JobNameLabel,
  Scale,
  StyledCareerHeader,
} from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/components/Questions/SkillAnswers/design';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';

interface IJobNameAndLabelsProps {
  isZeroState: boolean;
  options?: {
    label: IMultiLangString;
  }[];
  isMarginTop?: boolean;
  jobName?: string;
}

export const JobNameAndLabels = ({
  isZeroState,
  options,
  jobName,
  isMarginTop = false,
}: IJobNameAndLabelsProps) => {
  const getMultiLangString = useMultiLangString();

  return (
    <Header $isZeroState={isZeroState} $isMarginTop={isMarginTop}>
      {options && (
        <Scale>
          1 {getMultiLangString(options[0].label)}
          {' - '}
          {options.length} {getMultiLangString(options.at(options.length - 1)!.label)}
        </Scale>
      )}
      {jobName && (
        <StyledCareerHeader>
          <IconContainer>
            <IconBackground backgroundColor={isZeroState ? COLORS.INACTIVE : COLORS.COMPANY} />
            <Icon
              size={ICON_SIZES.SMALL}
              icon={ICONS.CAREER}
              color={isZeroState ? COLORS.INACTIVE : COLORS.COMPANY}
            />
          </IconContainer>
          <JobNameLabel>{jobName}</JobNameLabel>
        </StyledCareerHeader>
      )}
    </Header>
  );
};
