import React from 'react';

import styled from 'styled-components';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import { FocusAreaAnswers } from '~/pages/ReviewGiveFeedback/components/Questions/Components/FocusAreaAnswers';
import {
  Answers,
  CareerHeader,
  IconBackground,
  IconContainer,
} from '~/pages/ReviewGiveFeedback/components/Questions/design';

import { COLORS } from '~/styles';

const JobNameBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 12px 0;
`;

import type { IQuestionViewSkillProps } from './';

interface IAllAnswersPerJobProps {
  focusAreaQuestions: IQuestionViewSkillProps['focusAreaQuestions'];
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string; // we need to use this only from props, because for outside review -> hook will not work
  jobProfileName?: string;
}

export const AllAnswersPerJob = ({
  jobProfileName,
  focusAreaQuestions,
  useMultiLangString,
}: IAllAnswersPerJobProps) => {
  return (
    <Answers>
      {jobProfileName && (
        <JobNameBar>
          <div>
            <CareerHeader marginBottom="0px">
              <IconContainer>
                <IconBackground />
                <Icon size={ICON_SIZES.SMALL} icon={ICONS.CAREER} color={COLORS.COMPANY} />
              </IconContainer>
              <div>{jobProfileName}</div>
            </CareerHeader>
          </div>
        </JobNameBar>
      )}
      {focusAreaQuestions.map((fa) => {
        return (
          <FocusAreaAnswers
            useMultiLangString={useMultiLangString}
            key={fa.question.settings.focusArea.id}
            focusAreaQuestion={fa}
          />
        );
      })}
    </Answers>
  );
};
