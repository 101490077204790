import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import { createReducer } from 'redux-act';

import * as actions from './actions';

const defaultState = {
  name: '',
  description: '',
  participants: [],
  talkingPoints: [],
  isApprovalFlow: false,
  includeLinkMeeting: false,
};

export default createReducer(
  {
    [actions.setConversation]: (_, payload = {}) => {
      return {
        ...defaultState,
        ...payload,
        talkingPoints: orderBy(payload.talkingPoints || [], 'order'),
      };
    },
    [actions.setName]: (state, payload) => ({
      ...state,
      name: payload,
    }),
    [actions.setDescription]: (state, payload) => ({
      ...state,
      description: payload,
    }),
    [actions.setStartDate]: (state, payload) => ({
      ...state,
      startDate: payload,
      // update startDate in repeat.deadlines
      repeat: {
        ...(state.repeat || {}),
        deadlines: get(state, 'repeat.deadlines', []).map((item = {}, index) => {
          // apply changes only for first item
          // those dates should be equal to startDate/endDate
          if (!index) {
            return {
              ...item,
              startDate: payload,
            };
          }
          return item;
        }),
      },
    }),
    [actions.setEndDate]: (state, payload) => ({
      ...state,
      endDate: payload,
      // update startDate in repeat.deadlines
      repeat: {
        ...(state.repeat || {}),
        deadlines: get(state, 'repeat.deadlines', []).map((item = {}, index) => {
          // apply changes only for first item
          // those dates should be equal to startDate/endDate
          if (!index) {
            return {
              ...item,
              endDate: payload,
            };
          }
          return item;
        }),
      },
    }),
    [actions.addTalkingPoint]: (state, payload) => ({
      ...state,
      talkingPoints: state.talkingPoints.concat({
        ...payload,
        order: state.talkingPoints.length,
      }),
    }),
    [actions.addTalkingPoints]: (state, payload = []) => {
      const newTalkingPoints = [...(state.talkingPoints || [])];
      payload.forEach((tp) => newTalkingPoints.push(tp));

      newTalkingPoints.map((tp, idx) => {
        if (tp.order !== idx) {
          return { ...tp, order: idx };
        }
        return tp;
      });

      return {
        ...state,
        talkingPoints: newTalkingPoints,
      };
    },
    [actions.updateTalkingPoint]: (state, payload) => ({
      ...state,
      talkingPoints: (state.talkingPoints || []).map((p) => {
        if (p !== payload.pointToEdit) {
          return p;
        }
        return {
          ...p,
          ...(payload.update || {}),
        };
      }),
    }),
    [actions.removeTalkingPoint]: (state, payload) => ({
      ...state,
      talkingPoints: (state.talkingPoints || [])
        .filter((p) => p !== payload)
        .map((p, idx) => {
          if (p.order !== idx) {
            return { ...p, order: idx };
          }
          return p;
        }),
    }),
    [actions.removeTalkingPoints]: (state, payload) => ({
      ...state,
      talkingPoints: (state.talkingPoints || [])
        .filter((p) => !payload.includes(p.name))
        .map((p, idx) => {
          if (p.order !== idx) {
            return { ...p, order: idx };
          }
          return p;
        }),
    }),
    [actions.setTalkingPoints]: (state, payload) => ({
      ...state,
      talkingPoints: payload,
    }),
    [actions.incrementTalkingPointOrder]: (state, payload) => {
      const index = state.talkingPoints.indexOf(payload);
      if (index > 0) {
        return {
          ...state,
          talkingPoints: state.talkingPoints.map((point, idx) => {
            if (idx === index - 1) {
              return {
                ...payload,
                order: index - 1,
              };
            } else if (idx === index) {
              return {
                ...state.talkingPoints[index - 1],
                order: index,
              };
            }

            return point;
          }),
        };
      } else {
        return state;
      }
    },
    [actions.decrementTalkingPointOrder]: (state, payload) => {
      const index = state.talkingPoints.indexOf(payload);
      if (index < state.talkingPoints.length - 1) {
        return {
          ...state,
          talkingPoints: state.talkingPoints.map((point, idx) => {
            if (idx === index + 1) {
              return {
                ...payload,
                order: index + 1,
              };
            } else if (idx === index) {
              return {
                ...state.talkingPoints[index + 1],
                order: index,
              };
            }

            return point;
          }),
        };
      } else {
        return state;
      }
    },
    [actions.updateDeadline]: (state, payload) => ({
      ...state,
      deadline: payload,
    }),
    [actions.updateIsApproval]: (state, payload) => ({
      ...state,
      isApprovalFlow: payload,
    }),
    [actions.addSingleConversationUser]: (state, payload) => ({
      ...state,
      users: payload,
    }),
    [actions.removeSingleConversationUser]: (state, _payload) => ({
      ...state,
      users: [],
    }),
    [actions.addUsers]: (state, payload) => ({
      ...state,
      users: state.users.concat(payload),
    }),
    [actions.removeUser]: (state, payload) => {
      const coaches = { ...state.coaches };
      delete coaches[payload];

      return {
        ...state,
        coaches,
        users: state.users.filter((u) => u !== payload),
      };
    },
    [actions.setParticipants]: (state, payload) => ({
      // for user/coach setup
      ...state,
      participants: payload,
    }),
    [actions.setSubject]: (state, payload) => ({
      ...state,
      subject: payload,
    }),

    [actions.setCreatedBy]: (state, payload) => ({
      ...state,
      createdBy: payload,
    }),
    [actions.setCoach]: (state, payload) => ({
      // for admin setup
      ...state,
      coaches: {
        ...state.coaches,
        [payload.user]: payload.coach,
      },
    }),
    [actions.setRepeat]: (state, payload) => ({
      ...state,
      repeat: payload,
    }),
    [actions.setRepeatType]: (state, payload) => ({
      ...state,
      repeat: {
        ...(state.repeat || {}),
        type: payload,
      },
    }),
    [actions.setRepeatEvery]: (state, payload) => ({
      ...state,
      repeat: {
        ...(state.repeat || {}),
        every: payload,
      },
    }),
    [actions.setRepeatUntil]: (state, payload) => ({
      ...state,
      repeat: {
        ...(state.repeat || {}),
        until: payload,
      },
    }),
    [actions.setRepeatDeadlines]: (state, payload) => ({
      ...state,
      repeat: {
        ...(state.repeat || {}),
        deadlines: payload,
      },
    }),
    [actions.setIncludeLinkMeeting]: (state, payload) => ({
      ...state,
      includeLinkMeeting: payload,
    }),
  },
  defaultState,
);
