import React from 'react';

import { Trans } from '@lingui/macro';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICON_SIZES, ICONS } from '~/components/Icon';
import Modal from '~/components/Modal';
import RickTextView from '~/components/RickTextView';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';

import {
  ButtonWrapper,
  Definition,
  Details,
  Divider,
  EditButton,
  Header,
  Levels,
  Title,
  Wrapper,
} from './design';
import { FocusAreas } from './FocusAreas';
import { SkillCategory } from './SkillCategory';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';

import type { ISkill, ISkillCategory, WithReplace } from '@learned/types';

export interface ISkillModalProps {
  onClose: () => void;
  skill?: WithReplace<ISkill, { skillCategory: ISkillCategory }>;
  isLoading?: boolean;
  showEditButton?: boolean;
  onEdit?: (skillId?: string) => void;
  defaultExpandLevels?: number[]; // define what levels expand by default
  useMultiLangString?: () => (multiLangString: Record<string, string> | string) => string; // for outside review we need to path this as props because company is not defined
}

function SkillModal({
  onClose,
  skill,
  isLoading,
  showEditButton,
  onEdit,
  defaultExpandLevels = [],
  useMultiLangString: useMultiLangStringFromProps,
}: ISkillModalProps) {
  // here we define if we get hook from props or import
  const useMultiLangStringCustom = useMultiLangStringFromProps
    ? useMultiLangStringFromProps
    : useMultiLangString;

  const getMultiLangString = useMultiLangStringCustom();

  return (
    <Modal
      hideFooter
      isHideHeader
      maxHeight="90vh"
      width="750px"
      minWidth="750px"
      contentStyles={{
        boxSizing: 'border-box',
        borderRadius: '10px',
        maxHeight: '90vh',
        width: '750px',
        padding: 0,
      }}
      showDivider={false}
      borderRadius={10}
    >
      <Wrapper>
        <ShowSpinnerIfLoading loading={!!isLoading || !skill} height="500px">
          <Details>
            <Header>
              <Title>{getMultiLangString(skill?.name || '')}</Title>
              <div>
                <SkillCategory>
                  {getMultiLangString(skill?.skillCategory?.name || '')}
                </SkillCategory>

                <ButtonWrapper>
                  {showEditButton && (
                    <EditButton
                      onClick={() => onEdit?.(skill?.id)}
                      size={ButtonSize.BIG}
                      iconSize={ICON_SIZES.LARGE}
                      icon={ICONS.EDIT_PENCIL}
                      variant={ButtonVariant.ICON}
                    />
                  )}
                  <Button
                    onClick={onClose}
                    size={ButtonSize.MEDIUM}
                    variant={ButtonVariant.CLOSE}
                  />
                </ButtonWrapper>
              </div>
            </Header>
            <Definition>
              <h2>
                <Trans>Description</Trans>
              </h2>
              <RickTextView
                html={getMultiLangString(skill?.description || '')}
                color={COLORS.ICONS_PRIMARY}
                isHtmlWithoutStyles={undefined}
                className={undefined}
              />
            </Definition>
          </Details>
          <Divider />
          <Levels>
            <h2>
              <Trans>Levels</Trans>
            </h2>
            {skill?.skillCategory?.skillLevels?.map((skillLevel, index) => {
              const focusAreas = skill?.focusAreas?.find((area) => area.level === index);
              return focusAreas ? (
                <FocusAreas
                  focusAreas={focusAreas}
                  key={index}
                  levelName={skillLevel}
                  isExpandedByDefault={defaultExpandLevels.includes(index)}
                  useMultiLangString={useMultiLangStringCustom}
                />
              ) : null;
            })}
          </Levels>
        </ShowSpinnerIfLoading>
      </Wrapper>
    </Modal>
  );
}

export { SkillModal };
