import styled from 'styled-components';

import { COLORS } from '~/styles';

export const PreviousAnswerLegendWrapper = styled.div`
  display: flex;
  height: 18px;
  align-items: center;
  font-size: 12px;

  div.icon-container {
    width: 32px;
    background-color: ${COLORS.BG_ELEMENTS};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    height: 18px;
    margin-right: 10px;
  }
`;
